<template>
  <header class="header">
    <div class="row main-row">
      <div
        class="col-lg-6 col-md-10 col-6 align-self-center d-flex align-items-center pl-2 pr-0"
      >
        <a href="#" class="mr-md-4 mr-2 pr-lg-2 text-dark font-22" id="hear_menu_toggle">
          <i class="bx bx-menu-alt-left"></i>
        </a>
        <a href="/home">
          <img
            src="/images/gardening_new_log.jpg"
            width="200"
            height="39"
            alt="Gardning"
            class="img-fluid header-logo mr-3"
          />
        </a>
      </div>
      <div class="col-lg-6 col-md-2 col-6 d-flex justify-content-end align-items-center">
        <div class="form-style lang-dd mr-3">
          <div class="selectParent mr-3" style="">
            <select class="select-option" v-model="language" @change="saveLanguage()">
              <option value="en">EN</option>
              <option value="ar">AR</option>
            </select>
          </div>
        </div>
        <ul class="navbar-nav">
          <li class="nav-item dropdown notification-dd">
            <a
              class="nav-link dropdown-toggle waves-effect waves-dark mr-md-3 font-22 text-dark"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              id="notification_dd"
            >
              <span class="badge badge-danger count" v-show="this.count != 0">{{
                this.count
              }}</span>
              <!-- <img src="/images/notification.svg" width="20" /> -->
              <i class="bx bx-bell"></i>
            </a>
            <div
              class="dropdown-menu dropdown-menu-right"
              aria-labelledby="notification_dd"
            >
              <a
                class="dropdown-item"
                href="#"
                v-for="(data, index) in notificationList"
                :key="index"
                v-on:click="redirectTo(data)"
              >
                <div class="">
                  <p class="font-16 mb-2">{{ data.title }}</p>
                  <p class="font-14 mb-2">{{ data.message }}</p>
                  <p class="font-12 text-secondary mb-0">
                    <img src="/images/clock.svg" width="12" class="align-text-top" />
                    <em>{{ data.ago_time }}</em>
                  </p>
                </div>
              </a>

              <p class="text-center mb-0">
                <router-link
                  class="dropdown-item text-warning"
                  to="/notifications"
                  style="text-decoration: underline"
                  v-show="this.length > 3"
                  >{{ $t("label.Show More") }}
                </router-link>
              </p>
            </div>
          </li>
        </ul>
        <ul class="navbar-nav bg-light rounded-l">
          <li class="nav-item dropdown notification-dd cursor-pointer mll">
            <a
              class="nav-link dropdown-toggle waves-effect waves-dark mx-2 py-md-2 py-1 mlr"
              href="user-profile.php"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img
                :src="detail.avatar ? detail.avatar : '/images/imageplace-holder.svg'"
                width="32"
                height="32"
                class="rounded-circle object-cover"
              />
              <span class="text-secondary font-14 pl-2 hidden-none"
                >{{ detail.name }}<i class="bx bx-chevron-down align-middle"></i>
              </span>
            </a>
            <div
              class="dropdown-menu dropdown-menu-right"
              aria-labelledby="navbarDropdown"
              style="width: 245px"
            >
              <a class="dropdown-item cursor-pointer" href="/my-profile"
                ><i class="bx bx-user-circle font-20 align-text-top mr-1"></i>
                {{ $t("label.My Profile") }}</a
              >
              <!-- <a
                class="dropdown-item cursor-pointer"
                v-on:click="$router.push('/company-profile/' + detail?.company.id)"
                ><i class="bx bxs-briefcase font-20 align-text-top mr-1"></i
                >{{ $t("label.Company Profile") }}</a
              > -->
              <a class="dropdown-item cursor-pointer" href="/settings"
                ><i class="bx bxs-cog font-20 align-text-top mr-1"></i>
                {{ $t("label.Settings") }}</a
              >
              <a
                class="dropdown-item cursor-pointer"
                v-on:click="$router.push('/terms-conditions/' + type1)"
                ><i class="bx bxs-file font-20 align-text-top mr-1"></i
                >{{ $t("label.Terms & Condition") }}</a
              >

              <a class="dropdown-item cursor-pointer" v-on:click="logout()"
                ><i class="bx bxs-log-out-circle font-20 align-text-top mr-1"></i>
                {{ $t("label.Logout") }}</a
              >
            </div>
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>
<script>
import moment from "moment";
export default {
  name: "InnerHeader",
  data() {
    return {
      type1: "TERMS_AND_CONDITION",
      detail: {},
      token: localStorage.getItem("access_token"),
      currentTab: location.pathname.replace("/", ""),
      notificationList: [],
      count: "",
      length: "",
      language: localStorage.getItem("language"),
    };
  },

  mounted() {
    if (!this.language) {
      this.language = "ar";
      localStorage.setItem("language", "ar");
      moment.locale(localStorage.getItem("language"));
    }
    moment.locale(localStorage.getItem("language"));
    this.meCall();
    this.getNotification(0);
    this.getUnreadCount();
  },

  methods: {
    saveLanguage() {
      localStorage.setItem("language", this.language);
      moment.locale(localStorage.getItem("language"));
      this.$i18n.locale = this.lang;
      location.reload();
    },
    meCall() {
      this.$api
        .getAPI({
          _action: "me",
        })
        .then((res) => {
          this.detail = res.info;
          this.$localStorage.setItem("supplier_id", this.detail.company.supplier_id);
        })
        .catch(() => {});
    },
    logout() {
      localStorage.clear();
      this.$router.push("/login");
    },
    showDropdown() {
      var dropdownId = document.getElementById("dropdownMenuLink");
      if (dropdownId) {
        if (dropdownId.className.includes("show")) {
          dropdownId.classList.remove("show");
        } else {
          dropdownId.classList.add("show");
        }
      }
      var dropdownNotificationId = document.getElementById("dropdown-id");
      if (dropdownNotificationId) {
        if (dropdownNotificationId.className.includes("show")) {
          dropdownNotificationId.classList.remove("show");
        } else {
          dropdownNotificationId.classList.add("show");
        }
      }
      var dropdownMenuId = document.getElementById("dropdown-menu-id");
      if (dropdownMenuId) {
        if (dropdownMenuId.className.includes("show")) {
          dropdownMenuId.classList.remove("show");
        } else {
          dropdownMenuId.classList.add("show");
        }
      }
    },
    getNotification(offset) {
      if (offset == 0) {
        this.notificationList = [];
      }
      this.$api
        .getAPI({
          _action: "notifications",
        })
        .then((res) => {
          this.length = res.count;
          if (res.list.length <= 2) {
            for (var j in res.list) {
              res.list[j].created_at = moment(res.list[j].created_at).format(
                "DD MMM YYYY | HH:mm A"
              );
              this.notificationList.push(res.list[j]);
            }
          } else {
            for (var i = 0; i < 3; i++) {
              res.list[i].created_at = moment(res.list[i].created_at).format(
                "DD MMM YYYY | HH:mm A"
              );
              this.notificationList.push(res.list[i]);
            }
          }
        })
        .catch(() => {});
    },

    unreadNotification(id) {
      this.$api
        .putAPI({
          _action: "notification/" + id,
        })
        .then(() => {
          location.reload();
        })
        .catch(() => {});
    },
    getUnreadCount() {
      this.$api
        .getAPI({
          _action: "notification/count",
        })
        .then((res) => {
          this.count = res.unread_count;
        })
        .catch(() => {});
    },
  },
};
</script>

