<template>
  <main role="main">
    <div class="d-lg-flex" id="main-wraper">
      <div class="page-wrapper">
        <div class="page-content">
          <div class="row mb-4 justify-content-center">
            <div class="col-lg-12">
              <div class="row align-items-center">
                <div class="col-md-6 col-12">
                  <h2 class="page-title mb-0">
                    <a
                      href="javascript:void(0)"
                      v-on:click="$router.go(-1)"
                      class="back-arrow"
                    >
                      <i class="bx bx-arrow-back"></i
                    ></a>
                    {{ $t("label.Manage Services Orders") }}
                  </h2>
                </div>
                <!-- <div
                  class="col-md-6 col-7 text-right d-md-flex justify-content-end mb-3"
                ></div> -->
              </div>

              <ul class="nav nav-pills mb-3 tab-style-1" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link"
                    id="pending_orders-tab"
                    data-toggle="pill"
                    role="tab"
                    :class="{ active: currentTab == 'NEW_VISIT' }"
                    v-on:click="changeTab('NEW_VISIT')"
                    style="cursor: pointer"
                    >{{ $t("label.New Visit") }}</a
                  >
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link"
                    id="pending_orders-tab"
                    data-toggle="pill"
                    role="tab"
                    :class="{ active: currentTab == 'OUTGOING' }"
                    v-on:click="changeTab('OUTGOING')"
                    style="cursor: pointer"
                    >{{ $t("label.Outgoing") }}</a
                  >
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link"
                    id="pending_orders-tab"
                    data-toggle="pill"
                    role="tab"
                    :class="{ active: currentTab == 'VISIT_COMPLETE' }"
                    v-on:click="changeTab('VISIT_COMPLETE')"
                    style="cursor: pointer"
                  >
                    {{ $t("label.Visit Complete") }}
                  </a>
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link"
                    id="pending_orders-tab"
                    data-toggle="pill"
                    role="tab"
                    :class="{ active: currentTab == 'PENDING_NEW_WORK' }"
                    v-on:click="changeTab('PENDING_NEW_WORK')"
                    style="cursor: pointer"
                    >{{ $t("label.Pending New Work") }}
                  </a>
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link"
                    id="pending_orders-tab"
                    data-toggle="pill"
                    role="tab"
                    :class="{ active: currentTab == 'NEW_WORK_UNDER_PROGRESS' }"
                    v-on:click="changeTab('NEW_WORK_UNDER_PROGRESS')"
                    style="cursor: pointer"
                    >{{ $t("label.New Work Under Progress") }}
                  </a>
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link"
                    id="pending_orders-tab"
                    data-toggle="pill"
                    role="tab"
                    :class="{ active: currentTab == 'NEW_WORK_COMPLETE' }"
                    v-on:click="changeTab('NEW_WORK_COMPLETE')"
                    style="cursor: pointer"
                    >{{ $t("label.New Work Complete") }}
                  </a>
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link"
                    id="pending_orders-tab"
                    data-toggle="pill"
                    role="tab"
                    :class="{ active: currentTab == 'REJECTED' }"
                    v-on:click="changeTab('REJECTED')"
                    style="cursor: pointer"
                    >{{ $t("label.Rejected") }}
                  </a>
                </li>
              </ul>
              <div class="tab-content bg-white rounded p-3" id="pills-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="pending_orders"
                  role="tabpanel"
                  aria-labelledby="pending_orders-tab"
                >
                  <div class="row mb-3">
                    <div class="col-lg-4 col-md-4 d-md-flex mb-md-0 mb-2">
                      <div class="input-group page-cont-search border">
                        <span class="input-group-text"><i class="bx bx-search"></i></span>
                        <input
                          type="text"
                          class="form-control"
                          name=""
                          v-on:keyup.enter="getServiceList(0)"
                          :placeholder="$t('label.search here')"
                          v-model="searchObj.keyword"
                        />
                      </div>
                    </div>
                    <div class="col-md-4 mb-md-0 mb-2"></div>
                    <div class="col-md-4 mb-md-0 mb-2 text-right">
                      <Datepicker
                        v-model="date"
                        range
                        :clearable="false"
                        :enableTimePicker="false"
                        format="yyyy-MM-dd"
                        :placeholder="$t('label.Select Date')"
                        @update:model-value="getServiceList(0)"
                        :locale="lang"
                        :cancelText="$t('label.Cancel')"
                        :selectText="$t('label.Select')"
                      ></Datepicker>
                    </div>
                  </div>

                  <table class="table table-style-1 table-responsive" id="sorting_tbl">
                    <thead>
                      <tr>
                        <th>{{ $t("label.Order ID") }}</th>
                        <th>{{ $t("label.Date & Time") }}</th>
                        <th>{{ $t("label.Preferred Date") }}</th>
                        <th>{{ $t("label.Customer Name") }}</th>

                        <th>{{ $t("label.Mobile Number") }}</th>
                        <th>{{ $t("label.Email") }}</th>
                        <th>{{ $t("label.Location") }}</th>
                        <th>{{ $t("label.Price") }}</th>
                        <th>{{ $t("label.Action") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(data, index) in serviceOrder" :key="index">
                        <td>{{ data.shipping.order_id }}</td>
                        <td>{{ data.created_at }}</td>
                        <td>
                          <div>{{ data.preferred_date }}</div>
                          <span v-if="data.preferred_time == '06_AM_TO_10_AM'">{{
                            $t("label.06 AM TO 10 AM")
                          }}</span>
                          <span v-if="data.preferred_time == '10_AM_TO_02_PM'">{{
                            $t("label.10 AM TO 02 PM")
                          }}</span>
                          <span v-if="data.preferred_time == '02_PM_TO_06_PM'">{{
                            $t("label.02 PM TO 06 PM")
                          }}</span>
                          <span v-if="data.preferred_time == '06_PM_TO_10_PM'">{{
                            $t("label.06 PM TO 10 PM")
                          }}</span>
                        </td>
                        <td>{{ data.name }}</td>

                        <td>{{ data.phone_number }}</td>
                        <td>{{ data.email }}</td>
                        <td>{{ data.shipping.address }}</td>
                        <td>{{ data.net_amount }}</td>
                        <td>
                          <button
                            class="btn btn-action mr-2 mb-md-0 mb-2 text-secondary"
                            v-on:click="
                              $router.push(
                                '/service-order-detail/' + data.shipping.order_id
                              )
                            "
                          >
                            <i class="bx bx-show"></i>
                          </button>
                          <button
                            v-show="currentTab == 'PENDING'"
                            class="btn btn-action mr-2 mb-md-0 mb-2 text-success"
                            v-on:click="showModal(data.shipping.order_id, 'ACCEPTED')"
                          >
                            <i class="bx bxs-check-circle"></i>
                          </button>
                          <button
                            v-show="currentTab == 'PENDING'"
                            class="btn btn-action mr-2 mb-md-0 mb-2 text-danger"
                            v-on:click="showModal(data.shipping.order_id, 'REJECTED')"
                          >
                            <i class="bx bxs-x-circle"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <ErrorComponent @retry="getServiceList(0)" ref="errorComponent" />
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-lg-12 mt-3">
                  <div class="page-bottom-pagination text-right">
                    <Pagination
                      @page-change="pageChange"
                      @items-per-page-change="itemsPerPageChange"
                      ref="listPagination"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <TextModal ref="textModal">
    <div class="spx-modal h-100 justify-content-center">
      <div class="spx-modal-body mx-auto">
        <div class="modal-header pt-0">
          <button type="button" class="close" v-on:click="$refs.textModal.closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h5 class="modal-title">{{ $t("label.Reason") }}</h5>
        </div>
        <Form @submit="updateOrderStatus('REJECTED')">
          <div class="modal-body">
            <div class="floating-form-group">
              <label class="font-medium">{{ $t("label.Reason") }}</label>
              <Field
                id="reason"
                name="reason"
                v-model="reason"
                v-slot="{ field }"
                class="form-control"
                :rules="'required:' + $t(`label.Reason`)"
                :validateOnInput="true"
              >
                <textarea type="text-area" class="form-control" v-bind="field" />
              </Field>
            </div>
            <ErrorMessage name="reason" class="validation-msg mt-2" />
          </div>
          <div class="modal-footer pb-0">
            <button
              class="fill-btn"
              id="save-button"
              style="width: 18%; border-radius: 5px"
            >
              {{ $t("label.Save") }}
            </button>
            <button
              type="button"
              class="fil-btn"
              id="cancel-button"
              style="width: 18%; border-radius: 5px"
              v-on:click="$refs.textModal.closeModal()"
            >
              {{ $t("label.Cancel") }}
            </button>
          </div>
        </Form>
      </div>
    </div>
  </TextModal>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import moment from "moment";
import Datepicker from "@vuepic/vue-datepicker";
import TextModal from "../../../components/TextModal.vue";
import ErrorComponent from "@/components/ErrorComponent";
import Pagination from "@/components/Pagination.vue";
export default {
  name: "ManageProductOrder",
  components: {
    ErrorComponent,
    Pagination,
    TextModal,
    Form,
    Field,
    ErrorMessage,
    Datepicker,
  },
  data() {
    return {
      currentTab: "NEW_VISIT",
      serviceOrder: [],
      searchObj: {
        keyword: "",
        order_type: "SERVICE",
        status: "PENDING_AT_SUPPLIER",
      },
      orderId: "",
      reason: "",
      loader: true,
      offset: 0,
      itemsPerPage: "",
      currentPage: 1,
      lang: "ar",
    };
  },
  mounted() {
    this.lang = localStorage.getItem("language")
      ? localStorage.getItem("language")
      : "ar";
    this.getServiceList(0);
  },
  methods: {
    changeTab(tab) {
      if (tab == "NEW_VISIT") {
        this.searchObj.status = "PENDING_AT_SUPPLIER";
        this.searchObj.prev_order_id = 0;
        this.searchObj.order_type = "SERVICE";
        this.getServiceList(0);
      } else if (tab == "OUTGOING") {
        this.searchObj.status = "ACCEPTED";
        this.searchObj.prev_order_id = 0;
        this.searchObj.order_type = "SERVICE";
        this.getServiceList(0);
      } else if (tab == "VISIT_COMPLETE") {
        this.searchObj.status = "COMPLETED";
        this.searchObj.prev_order_id = 0;
        this.searchObj.order_type = "SERVICE";
        this.getServiceList(0);
      } else if (tab == "PENDING_NEW_WORK") {
        this.searchObj.status = "PENDING_AT_CUSTOMER";
        this.searchObj.prev_order_id = 1;
        this.searchObj.order_type = "SERVICE";
        this.getServiceList(0);
      } else if (tab == "NEW_WORK_UNDER_PROGRESS") {
        this.searchObj.prev_order_id = 1;
        this.searchObj.status = "ACCEPTED";
        this.searchObj.order_type = "SERVICE";
        this.getServiceList(0);
      } else if (tab == "NEW_WORK_COMPLETE") {
        this.searchObj.prev_order_id = 1;
        this.searchObj.status = "COMPLETED";
        this.searchObj.order_type = "SERVICE";
        this.getServiceList(0);
      } else if (tab == "REJECTED") {
        this.searchObj.status = "REJECTED";
        this.searchObj.order_type = "SERVICE";
        this.searchObj.prev_order_id = -1;
        this.getServiceList(0);
      }
    },
    pageChange(offset) {
      this.currentPage = offset;
      this.getServiceList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getServiceList(0);
    },
    showModal(id, status) {
      this.orderId = id;
      if (status == "REJECTED") {
        this.$refs.textModal.showModal();
      } else {
        this.updateOrderStatus(status);
      }
    },
    getServiceList(offset, order_by, sort_by) {
      this.loader = true;
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }
      if (order_by) {
        this.searchObj.order_by = order_by;
      }
      if (sort_by) {
        this.searchObj.sort_by = sort_by;
      }

      this.serviceOrder = [];

      if (this.date) {
        this.searchObj.from_date = moment(this.date[0]).format("YYYY-MM-DD");
        this.searchObj.to_date = moment(this.date[1]).format("YYYY-MM-DD");
      } else {
        this.searchObj.from_date = "";
        this.searchObj.to_date = "";
      }
      this.searchObj.offset = offset;
      this.$api
        .getAPI({
          _action: "orders",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          for (var k in res.list) {
            res.list[k].created_at = moment(res.list[k].created_at).format(
              "DD/MM/YYYY HH:mm a"
            );
            res.list[k].preferred_date = moment(res.list[k].preferred_date).format(
              "DD/MM/YYYY"
            );
            this.serviceOrder.push(res.list[k]);
          }

          if (this.$refs.listPagination) {
            if (res.total_count != null && res.total_count > 0) {
              this.$refs.listPagination.setTotalCount(res.count);
            } else {
              this.$refs.listPagination.setTotalCount(0);
            }
          }
          this.$refs.listPagination.currentPage = this.currentPage;
          if (this.$refs.errorComponent) {
            if (this.serviceOrder.length < 1) {
              this.$refs.errorComponent.setData(
                res,
                this.$t("label.No service available")
              );
            } else {
              this.$refs.errorComponent.setData(res, "");
            }
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          this.$refs.errorComponent.setData(0, undefined, "");
          this.$refs.errorComponent.updateLoader(false);
        });
    },
    updateOrderStatus(status) {
      var obj = {};
      if (status == "REJECTED") {
        obj.reason = "rejected";
      }

      obj.status = status;
      this.$api
        .putAPI({
          _action: "order/status/" + this.id,
          _body: obj,
        })
        .then((res) => {
          if (res) {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.$refs.textModal.closeModal();
            this.getServiceList(0);
          }
        })
        .catch(() => {});
    },
  },
};
</script>

