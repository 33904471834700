import { defineRule } from 'vee-validate';
import { i18n } from "@/main"; // ✅ Import i18n from main.js

defineRule("required", (value, [name, isSelect]) => {
  if (!value || value.length <= 0) {
    if (name) {
      if (!isSelect || isSelect == false) {
        return i18n.global.t("label.required_field", { name });
      } else {
        return i18n.global.t("label.select_field", { name });
      }
    }
    return i18n.global.t("label.generic_required");
  }
  return true;
});

defineRule('message', (value, [name]) => {
    if (value && value == "select") {
        return i18n.global.t("label.select_field", { name });
    }
    return true;
});

defineRule('email', value => {
    if (value && !/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/.test(value)) {
        return i18n.global.t("label.Please enter valid email");
    }
    return true;
});

defineRule('phone', value => {
    if (value && !/^[0-9]{8,12}$/.test(value)) {
        return i18n.global.t("label.Please enter valid contact number");
    }
    return true;
});

defineRule('pincode', value => {
    if (value && !/^[0-9]{6}$/.test(value)) {
        return  i18n.global.t("label.Please enter valid pin code");
    }
    return true;
});

defineRule('userName', (value, [name]) => {
    if (value && !/^[A-Za-z][A-Za-z_]{1,}$/.test(value)) {
        if (name) {
            return i18n.global.t("label.Please enter valid", { name });
        }
        return i18n.global.t("label.Please enter valid name");
    }
    return true;
});

defineRule('minLength', (value, [limit]) => {
    if (value.length < limit) {
        return i18n.global.t("label.This field must be at least characters", { limit });
    }
    return true;
});

defineRule('password', value => {
    if (value && !/^\S*$/.test(value)) {
        return i18n.global.t("label.Space is not allowed in password");
    }
    return true;
});

defineRule('confirmed', (value, [target]) => {

    if (value == target) {
        return true;
    }
    return i18n.global.t("label.Passwords must match");
});

defineRule('price', value => {
    if (value && !/^[1-9]\d*(\.\d{1,2}?)?$/.test(value)) {
        return i18n.global.t("label.Please enter valid price");
    }
    return true;
});

defineRule('numeric', value => {
    if (value && !/^[1-9]\d*(\.\d{1,2}?)?$/.test(value)) {
        return i18n.global.t("label.Please enter numeric values only");
    }
    return true;
});


defineRule("max_value", (value, [target]) => {
    if (value && !/^[0-9]\d*?$/.test(value)) {
        return i18n.global.t("label.Please enter numeric values only");
    }
    console.log("value ", value);
    if ((parseInt(value) > target)) {
        return i18n.global.t("label.Please enter numeric values below", { target });
    }
    return true;
});

defineRule("min_value", (value, [target]) => {
    if (value && !/^[0-9]\d*?$/.test(value)) {
        return i18n.global.t("label.Please enter numeric values only");
    }
    
    if ((parseInt(value) < target)) {
        return i18n.global.t("label.Please enter numeric values above", { target });
    }
    return true;
});