<template>
  <main role="main">
    <div class="d-lg-flex" id="main-wraper">
      <div class="page-wrapper">
        <div class="page-content">
          <div class="row mb-4 justify-content-center">
            <div class="col-lg-12">
              <div class="row align-items-center">
                <div class="col-md-6 col-5">
                  <h2 class="page-title mb-0">
                    <a
                      href="javascript:void(0)"
                      v-on:click="$router.go(-1)"
                      class="back-arrow"
                    >
                      <i class="bx bx-arrow-back"></i
                    ></a>
                    {{ $t("label.Services") }}
                  </h2>
                </div>
                <div class="col-md-6 col-7 text-right">
                  <button
                    type="button"
                    class="btn btn-brand-1 btn-sm"
                    v-on:click="$router.push('/master-service')"
                  >
                    <i class="bx bx-plus"></i> {{ $t("label.Add New Service") }}
                  </button>
                </div>
              </div>
              <div
                class="row my-3 align-items-center justify-content-between filter-options"
              >
                <div class="col-lg-3 col-md-4 d-md-flex mb-md-0 mb-2">
                  <div class="input-group page-cont-search">
                    <span class="input-group-text"><i class="bx bx-search"></i></span>
                    <input
                      type="text"
                      class="form-control"
                      name=""
                      v-on:keyup.enter="getServiceList(0)"
                      :placeholder="$t('label.search here')"
                      v-model="searchObj.keyword"
                    />
                  </div>
                </div>
                <div class="col-lg-4 col-md-8 text-right d-md-flex align-items-center">
                  <div class="selectParent mb-md-0 mb-2">
                    <select
                      class="select-option"
                      v-model="searchObj.category_id"
                      v-on:change="getServiceList(0)"
                    >
                      <option value="" disabled selected>
                        {{ $t("label.Select Category") }}
                      </option>
                      <option
                        v-for="(data, index) in categoryList"
                        :key="index"
                        :value="data.id"
                        v-on:click="getServiceList(0)"
                      >
                        {{ data.title }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <table class="table table-style-1 table-responsive" id="sorting_tbl">
                <thead>
                  <tr>
                    <th
                      class="sorting cursor-pointer"
                      id="id-category"
                      v-on:click="sorting('category', 'id-title')"
                    >
                      {{ $t("label.Category") }}
                    </th>
                    <th
                      class="sorting cursor-pointer"
                      id="id-title"
                      v-on:click="sorting('title', 'id-title')"
                    >
                      {{ $t("label.Service Name") }}
                    </th>
                    <th
                      class="sorting cursor-pointer"
                      id="id-price"
                      v-on:click="sorting('price', 'id-price')"
                    >
                      {{ $t("label.Visit Price") }}
                    </th>
                    <th>{{ $t("label.Action") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, index) in serviceList" :key="index">
                    <td>
                      <span class="text-info">{{ data?.category?.title }}</span>
                    </td>
                    <td>
                      <a href="javascript: void(0);" class="text-primary"
                      v-on:click="$router.push('/view-service/' + data?.supplier_price?.id)">{{
                        data?.title
                      }}</a>
                    </td>
                    <td v-if="data?.supplier_price">
                      <input
                        v-if="data?.supplier_price?.visit_charges"
                        type=""
                        onpaste="return false"
                        oncopy="return false"
                        class="form-control"
                        @keypress="$helperService.allowNumber($event)"
                        v-model="data.supplier_price.visit_charges"
                        style="width: 100px"
                      />
                      <input
                        v-show="!data?.supplier_price?.visit_charges"
                        type="text"
                        onpaste="return false"
                        oncopy="return false"
                        class="form-control"
                        @keypress="$helperService.allowNumber($event)"
                        v-model="data.visit_charges"
                        style="width: 100px"
                      />
                    </td>
                    <td v-else>-</td>
                    <td v-if="data?.supplier_price">
                      <button
                        class="btn btn-action btn-brand-1"
                        v-if="!data?.supplier_price?.visit_charges"
                        v-on:click="addCharges(index, data.supplier_price?.id)"
                      >
                        {{ $t("label.Save & Add") }}
                      </button>
                      <button
                        class="btn btn-brand-1"
                        v-on:click="addCharges(index, data.id)"
                        v-if="data?.supplier_price?.visit_charges"
                      >
                        {{ $t("label.Added") }}
                      </button>
                      <button
                        class="btn btn-action btn-brand-1"
                        v-on:click="edit(index, data?.supplier_price?.id)"
                        v-if="data?.supplier_price?.visit_charges"
                      >
                        {{ $t("label.Edit") }}
                      </button>
                      <button
                        class="btn btn-danger btn-brand-2"
                        v-on:click="showDeletePopup(data?.supplier_price?.id)"
                        v-if="data?.supplier_price?.visit_charges"
                      >
                        {{ $t("label.Delete") }}
                      </button>
                    </td>
                    <td v-else>-</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <ErrorComponent @retry="getServiceList(0)" ref="errorComponent" />
          </div>
          <div class="row mx-0 mb-4">
            <div class="col-lg-12 mt-5">
              <div class="page-bottom-pagination">
                <Pagination
                  @page-change="pageChange"
                  @items-per-page-change="itemsPerPageChange"
                  ref="listPagination"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <DeleteModal @remove="deleteRecord" ref="deleteModal" />
</template>

<script>
import ErrorComponent from "@/components/ErrorComponent";
import helperService from "../../services/HelperService";
// import { Form, Field, ErrorMessage } from "vee-validate";
import DeleteModal from "@/components/DeleteModal";
import Pagination from "@/components/Pagination.vue";
export default {
  name: "MyService",
  components: {
    DeleteModal,
    ErrorComponent,
    Pagination,
    //  Form,
    //  Field,
    // ErrorMessage
  },
  data() {
    return {
      serviceList: [],
      categoryList: [],
      subCategoryList: [],
      searchObj: {
        keyword: "",
        category_id: "",
        sub_category_id: "",
      },
      loader: true,
      offset: 0,
      itemsPerPage: "",
      currentPage: 1,
    };
  },
  mounted() {
    this.getServiceList(0);
    this.getCategory();
  },
  methods: {
    pageChange(offset) {
      this.currentPage = offset;
      this.getServiceList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getserviceList(0);
    },
    edit(index, id) {
      this.$router.push("/edit-service/" + id);
    },
    getServiceList(offset, order_by, sort_by) {
      this.loader = true;
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }
      var obj = {};
      if (this.searchObj.keyword) {
        obj.keyword = this.searchObj.keyword;
      }
      if (this.searchObj.category_id) {
        obj.category_id = this.searchObj.category_id;
      }

      if (order_by) {
        obj.order_by = order_by;
      }
      if (sort_by) {
        obj.sort_by = sort_by;
      }

      this.serviceList = [];

      obj.offset = offset;
      this.$api
        .getAPI({
          _action: "services",
          _body: obj,
        })
        .then((res) => {
          this.loader = false;
          this.serviceList = res.list;
          if (this.$refs.listPagination) {
            if (res.count != null && res.count > 0) {
              this.$refs.listPagination.setTotalCount(res.count);
            } else {
              this.$refs.listPagination.setTotalCount(0);
            }
          }
          this.$refs.listPagination.currentPage = this.currentPage;
          if (this.$refs.errorComponent) {
            if (this.serviceList.length < 1) {
              this.$refs.errorComponent.setData(
                res,
                this.$t("label.No service available")
              );
            } else {
              this.$refs.errorComponent.setData(res, "");
            }
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          this.$refs.errorComponent.setData(0, undefined, "");
          this.$refs.errorComponent.updateLoader(false);
        });
    },
    addCharges(index, id) {
      var obj = {};
      for (var i in this.serviceList) {
        if (i == index) {
          obj.service_id = id;
          obj.visit_charges = this.serviceList[i].supplier_price
            ? this.serviceList[i].supplier_price?.visit_charges
            : this.serviceList[i].visit_charges;
        }
      }
      this.$api
        .postAPI({
          _action: "service/visit-charge",
          _body: obj,
        })
        .then((res) => {
          this.getServiceList(0);
          this.$notify({
            type: "success",
            text: res.message,
          });
        })
        .catch(() => {});
    },
    showDeletePopup(id) {
      this.$refs.deleteModal.showModal(
        this.$t("label.Delete Event"),
        this.$t("label.Are you sure you want to delete"),
        id
      );
    },
    deleteRecord(id) {
      this.$api
        .deleteAPI({
          _action: "service/" + id,
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.$refs.deleteModal.closeModal();
          this.getServiceList(0);
        })
        .catch(() => {});
    },
    getCategory() {
      this.$api
        .getAPI({
          _action: "categories/service",
        })
        .then((res) => {
          this.categoryList = res.list;
        })
        .catch(() => {});
    },
    setAvailability(index, id) {
      var obj = {};
      for (var i in this.serviceList) {
        if (i == index) {
          obj.has_service_available = this.serviceList[i].service.has_service_available;
        }
      }
      this.$api
        .putAPI({
          _action: "service/availability/" + id,
          _body: obj,
        })
        .then((res) => {
          this.getServiceList(0);
          this.$notify({
            type: "success",
            text: res.message,
          });
        })
        .catch(() => {});
    },

    sorting(sortBy, id) {
      var obj = helperService.sorting(sortBy, id);
      this.getServiceList(0, obj.order_by, obj.sort_by);
    },
  },
};
</script>
