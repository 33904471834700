<template>
  <main role="main">
    <div id="main-wrapper" class="login-wrap">
      <div class="justify-content-end d-flex">
        <div class="selectParent mr-3 mt-3" style="width: 100px">
          <select class="select-option" v-model="language" @change="saveLanguage()">
            <option value="en">EN</option>
            <option value="ar">AR</option>
          </select>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row justify-content-md-center align-items-center min-vh-100">
          <div class="col-lg-6 col-md-9">
            <div class="row justify-content-center">
              <div class="col-lg-9">
                <div class="card px-lg-5 px-0 py-3">
                  <div class="card-body pb-0 text-center">
                    <img
                      src="images/gardening_new_log.jpg"
                      width="180"
                      height="37"
                      class="img-fluid mb-4 mx-auto"
                    />
                  </div>
                  <div class="card-body pt-1 pb-2">
                    <form class="form-style floating-form">
                      <Form @submit="forgotPassword" class="form-style">
                        <p class="font-20 font-medium">
                          {{ $t("label.Forgot your password?") }}
                        </p>
                        <p class="text-secondary">
                          {{
                            $t(
                              "label.Enter your registered phone number to reset your password"
                            )
                          }}
                        </p>

                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="phone">{{ $t("label.Phone") }}</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              id="phone"
                              name="phone"
                              v-model="detail.phone_number"
                              class="form-control filled"
                              :rules="'required:' + $t(`label.Phone`) + '|phone'"
                              :validateOnInput="true"
                              ><PhoneCode
                                id="mobile"
                                :placeholder="$t('label.Phone')"
                                v-model:countryCode="detail.country_code"
                                v-model="detail.phone_number"
                                v-model:dialCode="detail.dial_code"
                                v-bind="field"
                              />
                            </Field>
                          </div>
                          <ErrorMessage name="phone" class="validation-msg" />
                        </div>
                        <button
                          id="submit-button"
                          style="cursor: pointer"
                          class="btn btn-brand-1 btn-block"
                        >
                          {{ $t("label.Submit") }}
                        </button>
                      </Form>
                    </form>
                  </div>
                  <div class="card-footer text-center bg-white border-0">
                    <div class="links">
                      <i class="fa fa-angle-arrow-left" aria-hidden="true"></i>
                      {{ $t("label.Back to") }}
                      <a
                        href="/login"
                        style="color: #00bb59 !important"
                        class="ml-2 text-primary font-medium"
                      >
                        {{ $t("label.Login") }}</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>

  <!--verify OTP Modal-->
  <TextModal ref="textModal">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title font-20" style="line-height: 24px; font-weight: 600">
          {{ $t("label.Please enter the OTP") }} <br />
          {{ $t("label.to verify your account") }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          v-on:click="$refs.textModal.closeModal()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center pb-0">
        <img src="images/mobile-banking.png" alt="" width="64" height="64" class="mb-3" />
        <p class="font-18 mb-0">
          {{ $t("label.A code has been sent to your mobile number") }}
        </p>
      </div>
      <div
        class="modal-footer justify-content-center border-0 pb-3 pt-0 px-md-5 text-center"
      >
        <div id="otp" class="inputss d-flex flex-row justify-content-center">
          <input
            class="m-2 text-center form-control rounded"
            type="text"
            id="codeBox1"
            @input="moveToNext($event, 'codeBox2')"
            maxlength="1"
          />
          <input
            class="m-2 text-center form-control rounded"
            type="text"
            id="codeBox2"
            @input="moveToNext($event, 'codeBox3')"
            maxlength="1"
          />
          <input
            class="m-2 text-center form-control rounded"
            type="text"
            id="codeBox3"
            @input="moveToNext($event, 'codeBox4')"
            maxlength="1"
          />
          <input
            class="m-2 text-center form-control rounded"
            type="text"
            id="codeBox4"
            maxlength="1"
          />
        </div>
        <button
          type="button"
          class="btn btn-brand-1 btn-block w-50 mt-3"
          v-on:click="verifyOtp"
        >
          {{ $t("label.Validate") }}</button
        ><br />
        <p>
          {{ $t("label.Didnt received yet?") }}
          <a
            href="javascript:;void(0)"
            data-dismiss="modal"
            style="color: #00bb59 !important"
            v-on:click="resendOtp"
            >{{ $t("label.Resend") }}</a
          >.
        </p>
      </div>
    </div>
  </TextModal>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import TextModal from "@/components/TextModal";
import api from "../../services/WebService";
import PhoneCode from "../../components/PhoneCodePicker/PhoneCode.vue";
import moment from "moment";
import $ from "jquery";
export default {
  name: "ForgotPassword",
  components: {
    Form,
    Field,
    ErrorMessage,
    PhoneCode,
    TextModal,
  },
  data() {
    return {
      detail: {
        dial_code: "+966",
        country_code: "sa",
      },
      obj: { otp: "" },
      language: localStorage.getItem("language"),
    };
  },
  mounted() {
    if (!this.language) {
      this.language = "ar";
      localStorage.setItem("language", "ar");
      moment.locale(localStorage.getItem("language"));
    }
    $(document).ready(function () {
      $(
        ".floating-form-group input,.floating-form-group textarea,.floating-form select"
      ).change(function () {
        if ($(this).val() != "") {
          $(this).addClass("filled");
        } else {
          $(this).removeClass("filled");
        }
      });
    });
  },
  methods: {
    saveLanguage() {
      localStorage.setItem("language", this.language);
      moment.locale(localStorage.getItem("language"));
      this.$i18n.locale = this.lang;
      location.reload();
    },
    moveToNext(event, nextId) {
      if (event.target.value.length === 1) {
        const nextInput = document.getElementById(nextId);
        if (nextInput) {
          nextInput.focus();
        }
      }
    },
    showPopup() {
      this.$refs.textModal.showModal();
    },
    forgotPassword() {
      api
        .postAPI({
          _action: "forgot-password/mobile",
          _body: this.detail,
          _buttonId: "submit-button",
        })
        .then((res) => {
          if (res) {
            localStorage.setItem("phone_number", this.detail.phone_number);
            localStorage.setItem("dial_code", this.detail.dial_code);
            this.showPopup();
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {});
    },
    verifyOtp() {
      this.obj.otp =
        document.getElementById("codeBox1").value +
        "" +
        document.getElementById("codeBox2").value +
        "" +
        document.getElementById("codeBox3").value +
        "" +
        document.getElementById("codeBox4").value;
      this.obj.phone_number = this.detail.phone_number;
      this.obj.dial_code = this.detail.dial_code;
      if (this.obj.otp.length == 4) {
        this.$api
          .postAPI({
            _action: "verify-otp",
            _body: this.obj,
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.$router.push("/reset-password");
          })
          .catch((e) => {
            console.log("error", e);
          });
      } else {
        this.validate = true;
      }
    },
    resendOtp() {
      var obj = {
        dial_code: this.detail.dial_code,
        phone_number: this.detail.phone_number,
      };
      this.$api
        .postAPI({
          _action: "resend-otp",
          _body: obj,
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
        })
        .catch((e) => {
          console.log("error", e);
        });
    },
  },
};
</script>

