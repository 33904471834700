<template>
  <main role="main">
    <div class="d-lg-flex" id="main-wraper">
      <div class="page-wrapper">
        <div class="page-content">
          <div class="row mb-4 justify-content-center">
            <div class="col-lg-6">
              <div class="row align-items-center">
                <div class="col-md-12 col-sm-12 text-md-left">
                  <h2 class="page-title mb-0 pb-0">{{ $t("label.Settings") }}</h2>
                </div>
                <div class="col-md-6 text-right d-md-flex justify-content-end"></div>
              </div>
              <div
                class="card card-body form-style content-card mx-auto col-lg-12 col-sm-12 mt-3"
              >
                <Form ref="passForm" @submit="save">
                  <div class="row form-style floating-form">
                    <div class="col-md-12">
                      <Label class="font-bold">{{ $t("label.Change Password") }}</Label>
                    </div>
                    <div class="col-md-12 col-sm-6">
                      <div class="floating-form-group with-icon">
                        <Field
                          :type="oldPasswordFieldType"
                          id="CurrentPassword"
                          :class="detail.current_password ? 'filled' : ''"
                          name="Cuurent_password"
                          v-model="detail.current_password"
                          v-on:keydown.space="$event.preventDefault()"
                          :rules="
                            'required:' + $t(`label.Current Password`) + '|password'
                          "
                          :validateOnInput="true"
                        />
                        <label for="CurrentPassword">{{
                          $t("label.Current Password")
                        }}</label>
                        <i class="bx bx-lock icon"></i>
                        <!-- <a class="psw-icon toggle-password"><i class="bx bx-hide bx-show"></i></a> -->

                        <a class="psw-icon" v-on:click="switchVisibilityOld"
                          ><img :src="imageOld" width="20"
                        /></a>

                        <!-- <i class="bx bx-lock icon"></i>
                                <a href="javascript:void(0)" class="psw-icon toggle-password"><i class="bx bx-hide bx-show"></i></a> -->
                      </div>
                      <ErrorMessage name="Cuurent_password" class="validation-msg" />
                      <div class="floating-form-group mb-4 with-icon">
                        <Field
                          :type="newPasswordFieldType"
                          id="new_password"
                          :class="detail.new_password ? 'filled' : ''"
                          name="new_password"
                          v-model="detail.new_password"
                          v-on:keydown.space="$event.preventDefault()"
                          :rules="
                            'required:' +
                            $t(`label.New Password`) +
                            '|minLength:6|password'
                          "
                          :validateOnInput="true"
                        />
                        <label for="NewPassword">{{ $t("label.New Password") }}</label>
                        <i class="bx bx-lock icon"></i>
                        <a class="psw-icon" v-on:click="switchVisibilityNew"
                          ><img :src="imageNew" width="20"
                        /></a>
                      </div>
                      <ErrorMessage name="new_password" class="validation-msg" />
                      <div class="floating-form-group mb-4 with-icon">
                        <Field
                          :type="passwordFieldType"
                          id="confirm_password"
                          :class="confirm_password ? 'filled' : ''"
                          name="confirm_password"
                          v-model="confirm_password"
                          v-on:keydown.space="$event.preventDefault()"
                          :rules="
                            'required:' +
                            $t(`label.confirm password`) +
                            '|confirmed:@new_password'
                          "
                          :validateOnInput="true"
                        />

                        <label for="ConfirmPassword">{{
                          $t("label.Confirm Password")
                        }}</label>
                        <i class="bx bx-lock icon"></i>
                        <a v-on:click="switchVisibility" class="psw-icon"
                          ><img :src="image" width="20"
                        /></a>
                      </div>
                      <ErrorMessage name="confirm_password" class="validation-msg" />
                    </div>

                    <div class="col-lg-12 col-sm-12 text-center mt-4">
                      <button id="save-button" class="btn btn-brand-1 mx-2">
                        {{ $t("label.Save") }}
                      </button>
                      <button
                        type="button"
                        class="btn btn-brand-1 cancel mx-2"
                        v-on:click="$router.go(-1)"
                      >
                        {{ $t("label.Cancel") }}
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "AccountSettings",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      currentTab: "",
      newPasswordFieldType: "password",
      passwordFieldType: "password",
      oldPasswordFieldType: "password",
      image: "/images/show.png",
      imageNew: "/images/show.png",
      imageOld: "/images/show.png",
      detail: {},
      confirm_password: "",
    };
  },
  mounted() {
    this.changeTab("PASS");
  },
  methods: {
    changeTab(tab) {
      this.currentTab = tab;
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.image =
        this.image === "/images/show.png" ? "/images/hide.png" : "/images/show.png";
    },
    switchVisibilityNew() {
      this.newPasswordFieldType =
        this.newPasswordFieldType === "password" ? "text" : "password";
      this.imageNew =
        this.imageNew === "/images/show.png" ? "/images/hide.png" : "/images/show.png";
    },
    switchVisibilityOld() {
      this.oldPasswordFieldType =
        this.oldPasswordFieldType === "password" ? "text" : "password";
      this.imageOld =
        this.imageOld === "/images/show.png" ? "/images/hide.png" : "/images/show.png";
    },
    save() {
      this.$api
        .postAPI({
          _action: "change/password",
          _body: this.detail,
          _buttonId: "save-button",
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.$refs.passForm.resetForm();
          this.detail = {};
        })
        .catch(() => {});
    },
  },
};
</script>

