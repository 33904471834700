const messages = {
  en: {
    label: {
      required_field: "Please enter {name}",
      select_field: "Please select {name}",
      generic_required: "This field is required",
      "Please enter valid": "Please enter valid {name}",
      "This field must be at least characters":
        "This field must be at least {limit} characters",
      "Please enter numeric values below":
        "Please enter numeric values below {target}",
      "Please enter numeric values above":
        "Please enter numeric values above {target}",

      Dashboard: "Dashboard",
      Services: "Services",
      Products: "Products",
      "All Products": "All Products",
      "Product Orders": "Product Orders",
      "All Services": "All Services",
      "Services Orders": "Services Orders",
      "Product Delivery Charges": "Product Delivery Charges",
      Earnings: "Earnings",
      "Manage Service Orders": "Manage Service Orders",
      "Manage Product Orders": "Manage Product Orders",
      "Manage Products": "Manage Products",
      "Manage Services": "Manage Services",
      "Show More": "Show More",
      "My Profile": "My Profile",
      "Company Profile": "Company Profile",
      Settings: "Settings",
      "Terms & Condition": "Terms & Condition",
      Logout: "Logout",
      "search here": "search here...",
      Approved: "Approved",
      Requested: "Requested",
      Rejected: "Rejected",
      Status: "Status",
      "Change Password": "Change Password",
      "Current Password": "Current Password",
      "New Password": "New Password",
      "Confirm Password": "Confirm Password",
      Save: "Save",
      Cancel: "Cancel",
      "Company Details": "Company Details",
      Phone: "Phone",
      Email: "Email",
      "Company Name": "Company Name",
      "Change Logo": "Change Logo",
      images: "images",
      company_images: "Images",
      "All Withdrawal": "All Withdrawal",
      "Add Image": "Add Image",
      Action: "Action",
      "Upload Image": "Upload Image",
      "Upload a image": "Upload a image",
      "(Max 10 mb)": "(Max 10 mb)",
      "(Optional)": "(Optional)",
      "change Image": "Change Image",
      Close: "Close",
      "Delete Event": "Delete Event",
      "Are you sure you want to delete": "Are you sure you want to delete",
      "Invalid file formate, please use jpeg or png file.":
        "Invalid file formate, please use jpeg or png file.",
      "File must be less then 10 MB": "File must be less then 10 MB",
      "Withdrawal Request & Date Time": "Withdrawal Request & Date Time",
      "Payment Amount": "Payment Amount",
      "Withdrawal Number": "Withdrawal Number",
      "Withdrawal Status": "Withdrawal Status",
      "Order No. #": "Order No. #",
      "All Earnings": "All Earnings",
      "Total Order Payment": "Total Order Payment",
      "Your Earnings": "Your Earnings",
      Reason: "Reason",
      Back: "Back",
      "Cancel Request": "Cancel Request",
      "Withdrawal Request ID": "Withdrawal Request ID",
      Date: "Date",
      Time: "Time",
      "Process may take up to 24 hours_Please contact us if the withdrawal is not completed within this period":
        "Process may take up to 24 hours_Please contact us if the withdrawal is not completed within this period",
      "The money will be credited to your account within 1 minute to 3 working days":
        "The money will be credited to your account within 1 minute to 3 working days",
      "Withdrawal Detail": "Withdrawal Detail",
      Notifications: "Notifications",
      "No notification available": "No notification available",
      "Withdrawal Request Successful": "Withdrawal Request Successful",
      OK: "OK",
      Request: "Request",
      "Amount to Withdrawal": "Amount to Withdrawal",
      "Select Order": "Select Order",
      "Request for Withdrawal": "Request for Withdrawal",
      "Select Banks": "Select Banks",
      Select: "Select",
      "Accoount Number": "Account Number",
      "Confirm Account Number": "Confirm Account Number",
      "IBAN Number": "IBAN Number",
      "Mobile Number": "Mobile Number",
      "Name as given in bank": "Name as given in bank",
      "Minimum Order Free Shipping": "Minimum Order For Free Shipping",
      Profile: "Profile",
      "Operating Area": "Operating Area",
      "Change photo": "Change photo",
      Name: "Name",
      "Work Address": "Work Address",
      Add: "Add",
      "Add More": "Add More",
      "Are you sure you want to delete this address":
        "Are you sure you want to delete this address",
      "Please enter operating area": "Please enter operating area",
      "Operating Area/Region": "Operating Area/Region",
      "Add Products": "Add Products",
      "Product image": "Product image",
      "Product Name": "Product Name",
      Description: "Description",
      Category: "Category",
      "Sub-Category": "Sub-Category",
      Price: "Price",
      Details: "Details",
      "Out of stock": "Out of stock",
      "Edit Products": "Edit Products",
      "Product Description": "Product Description",
      "Gardening Catelog Products": "Gardening Catelog Products",
      "My Products": "My Products",
      "Request for New Product": "Request for New Product",
      Image: "Image",
      Added: "Added",
      "Save & Add": "Save & Add",
      "No product available": "No product available",
      "Gardening Catelog Services": "Gardening Catelog Services",
      "My Services": "My Services",
      "Service Name": "Service Name",
      "Visit Price": "Visit Price",
      "No service available": "No service available",
      "Edit Service": "Edit Service",
      "Service Image": "Service Image",
      "Service Description": "Service Description",
      "Allow Self Calculation": "Allow Self Calculation",
      "Price for Small/Medium Tree": "Price for Small/Medium Tree",
      "Price for Large Tree": "Price for Large Tree",
      "Order Detail": "Order Detail",
      "Order Id: #": "Order Id: #",
      Location: "Location",
      "Date & Time": "Date & Time",
      Completed: "Completed",
      Cancelled: "Cancelled",
      Accepted: "Accepted",
      "Payment Status": "Payment Status",
      S_No: "S.No.",
      Quantity: "Quantity",
      Amount: "Amount",
      Accept: "Accept",
      Reject: "Reject",
      "Mark Delivery Complete": "Mark Delivery Complete",
      Total: "Total",
      "Delivery Charges": "Delivery Charges",
      VAT: "VAT",
      Discount: "Discount",
      "Sub Total": "Sub Total",
      "Customer Name": "Customer Name",
      "Preferred Date": "Preferred Date",
      "Order ID": "Order ID",
      "Manage Products Orders": "Manage Products Orders",
      "Cancelled Orders": "Cancelled Orders",
      "Delivered Orders": "Delivered Orders",
      "Rejected Orders": "Rejected Orders",
      "Accepted Orders": "Accepted Orders",
      "Pending Orders": "Pending Orders",
      "Completed Orders": "Completed Orders",
      Tax: "Tax",
      "Manage Services Orders": "Manage Services Orders",
      Note: "Note",
      "Transaction Detail": "Transaction Detail",
      "Transaction Amount": "Transaction Amount",
      "Transaction Id": "Transaction Id",
      "Transaction Date & Time": "Transaction Date & Time",
      "Payment Mode": "Payment Mode",
      "Preferred Date & Time": "Preferred Date & Time",
      "Supplier Detail": "Supplier Detail",
      "Order Status": "Order Status",
      "Add New Service": "Add New Service",
      Include: "(Include)",
      "Add New Product": "Add New Product",
      "Select Date": "Select Date",
      "Select Category": "Select Category",
      "Product Commision": "Product Commision",
      "Service Commision": "Service Commision",
      "Available Balance": "Available Balance",
      "Request for Withdrwal": "Request for Withdrwal",
      "Withdrawal Not Available": "Withdrawal Not Available",
      Setting: "Settings",
      "Company Registration Number": "Company Registration Number",
      "Cash On Delivery": "Cash On Delivery",
      "Net Banking": "Net Banking",
      "Card Payment": "Card Payment",
      PayPal: "PayPal",
      Cash: "Cash",
      Pending: "Pending",
      Success: "Success",
      Failed: "Failed",
      "Pending at supplier": "Pending at supplier",
      "Pending at customer": "Pending at customer",
      "Payment Pending": "Payment Pending",
      "Type Here": "Type Here...",
      "Please enter": "Please enter ",
      "Please select": "Please select ",
      "This field is required": "This field is required",
      "Please enter valid email": "Please enter valid email",
      "Please enter valid contact number": "Please enter valid contact number",
      "Please enter valid pin code": "Please enter valid pin code",
      "Please enter valid name": "Please enter valid name",
      "This field must be at least": "This field must be at least ",
      characters: "characters",
      "Space is not allowed in password": "Space is not allowed in password",
      "Passwords must match": "Passwords must match",
      "Please enter valid price": "Please enter valid price",
      "Please enter numeric values only": "Please enter numeric values only",
      "New Work Complete": "New Work Complete",
      "New Work Under Progress": "New Work Under Progress",
      "Pending New Work": "Pending New Work",
      "Visit Complete": "Visit Complete",
      Outgoing: "Outgoing",
      "New Visit": "New Visit",
      "New Work Order": "New Work Order",
      "New Order": "New Order",
      "Create New Order": "Create New Order",
      "Preferred Time": "Preferred Time",
      "Time Needed": "Time Needed",
      "Edit Order": "Edit Order",
      "Please enter price": "Please enter price",
      "Please enter description": "Please enter description",
      "Please enter time needed": "Please enter time needed",
      "Please select preferred date": "Please select preferred date",
      SubTotal: "SubTotal",
      "06 AM TO 10 AM": "06 AM TO 10 AM",
      "10 AM TO 02 PM": "10 PM TO 02 PM",
      "02 PM TO 06 PM": "02 PM TO 06 PM",
      "06 PM TO 10 PM": "06 PM TO 10 PM",
      Sr_No: "Sr No.",
      "Admin Commission": "Admin Commission",
      Submit: "Submit",
      Update: "Update",
      "Has Grass Trimming": "Has Grass Trimming",
      "Has Tree Trimming": "Has Tree Trimming",
      "Grass Trimming": "Grass Trimming",
      "Tree Trimming": "Tree Trimming",
      Edit: "Edit",
      Title: "Title",
      Delete: "Delete",
      "Pay to Admin": "Pay to Admin",
      "Order Type": "Order Type",
      "No record found": "No record found",
      Commission: "Commission",
      "Order Amount": "Order Amount",
      "Including Tax": "Including Tax",
      "Inspection Discount": "Inspection Discount",
      "Visit Charges": "Visit Charges",
      "Range From": "Range From",
      "Range To": "Range To",
      "Additional information": "Additional information",
      "Extra Note": "Extra Note",
      "Order Number": "Order No",
      "Company Name (English)": "Company Name (English)",
      "Company Name (Arabic)": "Company Name (Arabic)",
      "Sign In": "Sign In",
      "By clicking here, I state that I have read and accepted the":
        "By clicking here, I state that I have read and accepted the",
      "terms and conditions": "terms and conditions",
      "Already have an account?": "Already have an account?",
      Login: "Login",
      Signup: "Signup",
      Password: "Password",
      "Dont have an account": "Don't have an account?",
      "Forgot Password?": "Forgot Password?",
      "Back to": "Back to",
      "Forgot your password?": "Forgot your password?",
      "Enter your registered phone number to reset your password":
        "Enter your registered phone number to reset your password",
      "Please enter the OTP": "Please enter the OTP",
      "to verify your account": "to verify your account",
      "A code has been sent to your mobile number":
        "A code has been sent to your mobile number",
      Validate: "Validate",
      "Didnt received yet?": "Didn't received yet?",
      Resend: "Resend",
      "OTP Confirmation": "OTP Confirmation",
      "Please enter the verification code sent to your email":
        "Please enter the verification code sent to your email.",
      "OTP is required": "OTP is required",
      "Resend OTP": "Resend OTP",
      "OTP Verified": "OTP Verified",
      "Your Request is Pending Approval": "Your Request is Pending Approval",
      "Your Request to join for successful and is now pending approval from your team admin":
        "Your Request to join for successful and is now pending approval from your team admin.",
      Ok: "ok",
      "View Products": "View Products",
      "View Service": "View Service",
    },
  },
  ar: {
    label: {
      required_field: "الرجاء الدخول {name}",
      select_field: "الرجاء التحديد {name}",
      generic_required: "هذه الخانة مطلوبه",
      "Please enter valid": "الرجاء إدخال صالحة {name}",
      "This field must be at least characters":
        "يجب أن يكون هذا الحقل على الأقل {limit} الشخصيات",
      "Please enter numeric values below":
        "الرجاء إدخال القيم الرقمية أدناه {target}",
      "Please enter numeric values above":
        "الرجاء إدخال القيم الرقمية أعلاه {target}",

      Dashboard: "لوحة التحكم",
      Services: "خدمات",
      Products: "منتجات",
      "All Products": "جميع المنتجات",
      "Product Orders": "طلبات المنتجات",
      "All Services": "جميع الخدمات",
      "Services Orders": "طلبات الخدمات",
      "Product Delivery Charges": "رسوم توصيل المنتج",
      Earnings: "الأرباح",
      "Manage Service Orders": "إدارة طلبات الخدمة",
      "Manage Product Orders": "إدارة طلبات المنتجات",
      "Manage Products": "إدارة المنتجات",
      "Manage Services": "إدارة الخدمات",
      "Show More": "أظهر المزيد",
      "My Profile": "ملفي",
      "Company Profile": "ملف الشركة",
      Settings: "إعدادات",
      "Terms & Condition": "الشروط والأحكام",
      Logout: "تسجيل خروج",
      "search here": "ابحث هنا...",
      Approved: "موافقة",
      Requested: "مطلوب",
      Rejected: "مرفوض",
      Status: "الحاله",
      "Change Password": " تغيير كلمة المرور",
      "Current Password": " كلمة المرور الحالية",
      "New Password": " كلمة المرور الجديدة",
      "Confirm Password": " تأكيد كلمة المرور",
      Save: " حفظ",
      Cancel: " إلغاء",
      "Company Details": " معلومات الشركة",
      Phone: " الهاتف",
      Email: " البريد الإلكتروني",
      "Company Name": " اسم الشركة",
      "Change Logo": " تغيير الشعار",
      images: " الصور",
      "All Withdrawal": "جميع عمليات السحب",
      "Add Image": " إضافة صورة",
      Action: " الإجراء",
      "Upload Image": "رفع الصورة",
      "Upload a image": "رفع صورة",
      "(Max 10 mb)": "( الحد الأقصى 10 ميجا بايت)",
      "(Optional)": "( اختياري)",
      "change Image": " تغيير الصورة",
      Close: " إغلاق",
      "Delete Event": " حذف الحدث",
      "Are you sure you want to delete": " هل أنت متأكد أنك تريد الحذف",
      "Invalid file formate, please use jpeg or png file.":
        "صيغة الملف غير صالحة، يرجى استخدام ملف jpeg أو png. ",
      "File must be less then 10 MB":
        " يجب أن يكون حجم الملف أقل من 10 ميغابايت",
      "Withdrawal Request & Date Time": " طلب السحب والتاريخ والوقت",
      "Payment Amount": " مبلغ الدفع",
      "Withdrawal Status": " حالة طلب السحب ",
      "Withdrawal Number": "رقم السحب",
      "Order No. #": " رقم الطلب #",
      "All Earnings": " جميع الأرباح",
      "Total Order Payment": "إجمالي مبلغ الدفع",
      "Your Earnings": "إيراداتك",
      Reason: " السبب",
      Back: "الرجوع",
      "Cancel Request": " إلغاء الطلب",
      "Withdrawal Request ID": " رقم طلب السحب",
      Date: " التاريخ",
      Time: " الوقت",
      "Process may take up to 24 hours. Please contact us if the withdrawal is not completed within this period":
        " قد تستغرق العملية فترة تصل إلى 24 ساعة. يرجى الاتصال بنا إذا لم يكتمل السحب خلال هذه الفترة",
      "The money will be credited to your account within 1 minute to 3 working days":
        " سيتم إضافة الأموال إلى حسابك خلال دقيقة واحدة إلى 3 أيام عمل",
      "Withdrawal Detail": " تفاصيل السحب",
      Notifications: " الإشعارات",
      "No notification available": "لايوجد اشعارات",
      "Withrawal Request Successful": " تم قبول طلب السحب",
      OK: " حسنا",
      Request: " طلب",
      "Amount to Withdrawal": " مبلغ السحب",
      "Select Order": " حدد الطلب",
      "Request for Withdrawal": " طلب السحب",
      "Select Banks": " حدد البنك",
      Select: "حدد",
      "Accoount Number": " رقم الحساب",
      "Confirm Account Number": " تأكيد رقم الحساب",
      "IBAN Number": " رقم الآيبان",
      "Mobile Number": " رقم الجوال",
      "Name as given in bank": " الاسم كما هو موجود في البنك",
      "Minimum Order Free Shipping": " الحد الأدنى للطلب للشحن المجاني ",
      Profile: " الملف الشخصي",
      "Operating Area": " منطقة التشغيل",
      "Change photo": " تغيير الصورة",
      Name: " الاسم",
      "Work Address": " عنوان العمل",
      Add: " إضافة",
      "Add More": "إضافة المزيد",
      "Are you sure you want to delete this address":
        " هل أنت متأكد أنك تريد حذف هذا العنوان",
      "Please enter operating area": " الرجاء إدخال منطقة التشغيل",
      "Operating Area/Region": " منطقة التشغيل/المنطقة",
      "Add Products": " إضافة منتجات",
      "Product image": " صورة المنتج",
      "Product Name": " اسم المنتج",
      Description: " الوصف",
      Category: "التصنيف",
      "Sub-Category": " تصنيف فرعي",
      Price: "السعر",
      Details: "التفاصيل",
      "Out of stock": " غير متوفر في المخزون",
      "Edit Products": " تعديل المنتجات",
      "Product Description": " وصف المنتج",
      "Gardening Catelog Products": "مجموعة منتجات الحدائق",
      "My Products": " منتجاتي",
      "Request for New Product": " طلب اضافة منتج جديد ",
      Image: "صورة",
      Added: " تمت الإضافة",
      "Save & Add": " حفظ وإضافة",
      "No product available": " لا يوجد منتج متاح",
      "Gardening Catelog Services": "مجموعة خدمات الحدائق",
      "My Services": " خدماتي",
      "Service Name": " اسم الخدمة",
      "Visit Price": " سعر الزيارة",
      "No service available": " لا توجد خدمة متاحة",
      "Edit Service": " تحرير الخدمة",
      "Service Image": " صورة الخدمة",
      "Service Description": " وصف الخدمة",
      "Allow Self Calculation": " السماح بالحساب الذاتي",
      "Price for Small/Medium Tree": " سعر الشجرة الصغيرة/المتوسطة",
      "Price for Large Tree": " سعر الشجرة الكبيرة",
      "Order Detail": " تفاصيل الطلب",
      "Order Id: #": " رقم الطلب : #",
      Location: " الموقع",
      "Date & Time": " التاريخ والوقت",
      Completed: " مكتمل",
      Cancelled: " ملغى",
      Accepted: " مقبول",
      "Payment Status": " حالة الدفع",
      S_No: "الرقم التسلسلي",
      Quantity: " الكمية",
      Amount: "المبلغ",
      Accept: "قبول",
      Reject: "رفض",
      "Mark Delivery Complete": " وضع علامة على اكتمال التسليم",
      Total: " المجموع",
      "Delivery Charges": " رسوم التوصيل",
      VAT: " ضريبة القيمة المضافة",
      Discount: " الخصم",
      "Sub Total": " المجموع الفرعي",
      "Customer Name": " اسم العميل",
      "Preferred Date": " التاريخ المفضل",
      "Order ID": " رقم الطلب",
      "Manage Products Orders": " إدارة طلبات المنتجات",
      "Cancelled Orders": " الطلبات الملغاة",
      "Delivered Orders": " الطلبات التي تم تسليمها",
      "Rejected Orders": " الطلبات المرفوضة",
      "Accepted Orders": " الطلبات المقبولة",
      "Pending Orders": " الطلبات المعلقة",
      "Completed Orders": " الطلبات المكتملة",
      Tax: "الضريبة",
      "Manage Services Orders": " إدارة طلبات الخدمات",
      Note: "ملحوظة",
      "Transaction Detail": "تفاصيل الصفقة",
      "Transaction Amount": "قيمة التحويل",
      "Transaction Id": "رقم المعاملة",
      "Transaction Date & Time": "تاريخ ووقت المعاملة",
      "Payment Mode": "طريقة الدفع",
      "Preferred Date & Time": "التاريخ والوقت المفضل",
      "Supplier Detail": "تفاصيل المورد",
      "Order Status": "حالة الطلب",
      "Add New Service": "إضافة خدمة جديدة",
      Include: "(يشمل)",
      "Add New Product": "إضافة منتج جديد",
      "Select Date": "حدد تاريخ",
      "Select Category": "اختر الفئة",
      "Product Commision": "عمولة المنتج",
      "Service Commision": "عمولة الخدمات",
      "Available Balance": "الرصيد المتوفر",
      "Request for Withdrwal": "طلب الانسحاب",
      "Withdrawal Not Available": "السحب غير متاح",
      Setting: "إعدادات",
      "Company Registration Number": "رقم تسجيل الشركة",
      "Cash On Delivery": "الدفع عند الاستلام",
      "Net Banking": "صافي المصرفية",
      "Card Payment": "بطاقه ائتمان",
      PayPal: "باي بال",
      Cash: "نقدي",
      Pending: "قيد الانتظار",
      Success: "نجاح",
      Failed: "فشل",
      "Pending at supplier": "في انتظار المورد",
      "Pending at customer": "في انتظار العملاء",
      "Payment Pending": "انتظار الدفع",
      "Type Here": "أكتب هنا...",

      "Please enter": "تفضل",
      "Please select": "الرجاء التحديد",
      "This field is required": "هذه الخانة مطلوبه",
      "Please enter valid email": "الرجاء إدخال بريد إلكتروني صحيح",
      "Please enter valid contact number": "الرجاء إدخال رقم اتصال صالح",
      "Please enter valid pin code": "الرجاء إدخال رمز التعريف الشخصي الصحيح",
      "Please enter valid name": "الرجاء إدخال اسم صالح",
      "This field must be at least": "يجب أن يكون هذا الحقل على الأقل",
      characters: "الشخصيات",
      "Space is not allowed in password":
        "المساحة غير مسموح بها في كلمة المرور",
      "Passwords must match": "يجب أن تتطابق كلمات المرور",
      "Please enter valid price": "الرجاء إدخال سعر صالح",
      "Please enter numeric values only": "الرجاء إدخال قيم رقمية فقط",
      "New Work Complete": "العمل الجديد مكتمل",
      "New Work Under Progress": "العمل الجديد قيد التقدم",
      "Pending New Work": "العمل الجديد معلق",
      "Visit Complete": "الزيارة مكتملة",
      Outgoing: "صادر",
      "New Visit": "زيارة جديدة",
      "New Work Order": "أمر عمل جديد",
      "New Order": "طلب جديد",
      "Create New Order": "إنشاء طلب جديد",
      "Preferred Time": "الوقت المفضل",
      "Time Needed": "الوقت اللازم",
      "Edit Order": "تحرير الطلب",
      "Please enter price": "الرجاء إدخال السعر",
      "Please enter description": "الرجاء إدخال الوصف",
      "Please enter time needed": "الرجاء إدخال الوقت اللازم",
      "Please select preferred date": "الرجاء تحديد التاريخ المفضل",
      SubTotal: "المجموع الفرعي",
      "06 AM TO 10 AM": "06 صباحًا إلى 10 صباحًا إلى",
      "10 AM TO 02 PM": "10 مساءً إلى 02 صباحًا إلى",
      "02 PM TO 06 PM": "02 مساءً إلى 06 مساءً",
      "06 PM TO 10 PM": "06 مساءً إلى 10 مساءً",
      Sr_No: "الرقم التسلسلي",
      "Admin Commission": "العمولة",
      Submit: "يُقدِّم",
      Update: "تحديث",
      "Has Grass Trimming": "لديها تقليم العشب",
      "Has Tree Trimming": "لديه تقليم شجرة",
      "Grass Trimming": "تقليم العشب",
      "Tree Trimming": "تقليم شجرة",
      Edit: "عنوان",
      Title: "عنوان",
      Delete: "يمسح",
      "Pay to Admin": "الدفع إلى المشرف",
      "Order Type": "نوع الطلب",
      "No record found": "لا يوجد سجلات",
      Commission: "عمولة",
      "Order Amount": "كمية الطلب",
      "Including Tax": "متضمن للضريبة",
      "Inspection Discount": "خصم التفتيش",
      "Visit Charges": "رسوم الزيارة",
      company_images: "صور الشركة",
      "Range From": "النطاق من",
      "Range To": "النطاق إلى",
      "Additional information": "معلومات إضافية",
      "Extra Note": "ملاحظة إضافية",
      "Order Number": "رقم الطلب",
      "Sign In": "تسجيل الدخول",
      "By clicking here, I state that I have read and accepted the":
        "بالضغط هنا، أقر بأنني قرأت وقبلت",
      "terms and conditions": "الشروط والأحكام",
      "Already have an account?": "هل لديك حساب بالفعل؟",
      Login: "تسجيل الدخول",
      Signup: "اشتراك",
      Password: "كلمة المرور",
      "Dont have an account": "ليس لديك حساب؟",
      "Forgot Password?": "هل نسيت كلمة السر؟",
      "Back to": "العودة إلى",
      "Forgot your password?": "نسيت كلمة السر؟",
      "Enter your registered phone number to reset your password":
        "أدخل رقم هاتفك المسجل لإعادة تعيين كلمة المرور الخاصة بك",
      "Please enter the OTP": "الرجاء إدخال كلمة المرور لمرة واحدة",
      "to verify your account": "للتحقق من حسابك",
      "A code has been sent to your mobile number":
        "تم إرسال الرمز إلى رقم هاتفك المحمول",
      Validate: "التحقق من صحة",
      "Didnt received yet?": "لم تتلق بعد؟",
      Resend: "إعادة الإرسال",
      "OTP Confirmation": "تأكيد كلمة المرور لمرة واحدة",
      "Please enter the verification code sent to your email":
        "الرجاء إدخال رمز التحقق المرسل إلى بريدك الإلكتروني.",
      "OTP is required": "مطلوب مكتب المدعي العام",
      "Resend OTP": "إعادة إرسال تأكيد كلمة المرور لمرة",
      "OTP Verified": "تم التحقق من إعادة إرسال تأكيد كلمة .",
      "Your Request is Pending Approval": "طلبك في انتظار الموافقة",
      "Your Request to join for successful and is now pending approval from your team admin":
        "لقد نجح طلبك للانضمام وهو الآن في انتظار الموافقة من مسؤول فريقك.",
      Ok: "نعم",
      "View Products": "عرض المنتجات",
      "View Service": "عرض الخدمة",
    },
  },
};

export default messages;
