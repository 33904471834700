<template>
  <div class="page-content">
    <div class="row mb-4 justify-content-center">
      <div class="col-lg-12">
        <div class="row align-items-center">
          <div class="col-md-6 text-md-left">
            <h2 class="page-title mb-md-0">
              <a href="javascript:void(0)" v-on:click="$router.go(-1)" class="back-arrow">
                <i class="bx bx-arrow-back"></i></a
              >{{ $t("label.Edit Products") }}
            </h2>
          </div>
          <!-- <div class="col-md-6 text-right d-md-flex justify-content-end mb-3"></div> -->
        </div>
        <div class="card card-body">
          <div class="row mb-md-4 mb-3">
            <div class="col-lg-12 col-md-12 text-center">
              <label for="">{{ $t("label.Product image") }}</label>
              <div class="custom-select-img">
                <div class="image-container mb-2 mx-auto col-lg-12 mb-3">
                  <div class="row">
                    <div
                      class="mb-3 col-md-4"
                      v-for="(image, index) in ImageList"
                      :key="index"
                    >
                      <div class="text-center">
                        <img
                          class="mx-2"
                          style="
                            border: 1px solid rgb(204, 204, 204);
                            width: 220px !important;
                            height: 150px !important;
                          "
                          :src="
                            image.image
                              ? image.image
                              : '/static/img/upload-placeholder.jpg'
                          "
                          ref="'image'"
                          id="img_hoder_1"
                        />
                        <i
                          sty
                          class="bi bi-x-circle-fill close"
                          v-on:click="deleteImage(image, index)"
                        ></i>
                        <br />
                      </div>
                    </div>
                  </div>

                  <input
                    type="file"
                    class="btn upload-img"
                    id="AddPhoto"
                    accept="logo/*"
                    multiple
                    @change="onChange($event)"
                    hidden="true"
                  />
                  <div
                    style="cursor: pointer"
                    class="mb-3 justify-content-center"
                    v-if="ImageList.length <= 0"
                    v-on:click="openImages"
                  >
                    <div class="image-upload-wrap">
                      <div class="drag-text text-center">
                        <img
                          id="preview"
                          :src="'/images/Imageplace-holder.svg'"
                          width="40"
                        />
                        <h3 class="heading font-20">{{ $t("label.Upload a image") }}</h3>
                        <p class="font-12 text-secondary mb-1" for="pickFile">
                          PNG, GIF, JPEG. {{ $t("label.(Max 10 mb)") }}
                        </p>
                        <p class="font-12 text-secondary mb-0">
                          {{ $t("label.(Optional)") }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="ImageList.length > 0"
                    class="rounded mx-2"
                    style="margin: auto; width: 100%; padding: 10px"
                  >
                    <button
                      class="btn"
                      style="background-color: #17a2b8"
                      @click="openImages()"
                    >
                      {{ $t("label.Add More") }} <i class="bi bi-plus-circle-fill"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-9">
              <div class="form-group">
                <label class="text-secondary-2 font-14 mb-0">{{
                  $t("label.Product Name")
                }}</label>
                <p>{{ detail.title }}</p>
              </div>
              <div class="form-group">
                <label class="text-secondary-2 font-14 mb-0">{{
                  $t("label.Product Description")
                }}</label>
                <p>
                  {{ detail.description }}
                </p>
              </div>
            </div>
            <div class="col-12 mt-4">
              <h4 class="font-18 font-medium">{{ $t("label.Details") }}</h4>
            </div>
            <div
              class="col-md-4 col-6"
              v-for="(data, index) in detail.attributes"
              :key="index"
            >
              <label class="text-secondary-2 font-14 mb-0">{{ data.title }}</label>
              <p>{{ data.values }}</p>
            </div>
            <div class="col-12 col-6">
              <hr />
            </div>
            <div class="col-md-4">
              <label class="text-secondary-2 font-14 mb-0">{{
                $t("label.Category")
              }}</label>
              <p>{{ detail?.category?.title }}</p>
            </div>
            <div class="col-md-4">
              <label class="text-secondary-2 font-14 mb-0">{{
                $t("label.Sub-Category")
              }}</label>
              <p>{{ detail?.sub_category?.title }}</p>
            </div>
            <div class="col-md-4 form-group">
              <label class="text-secondary-2 font-14 mb-0">{{ $t("label.Price") }}</label>
              <input
                v-if="detail.supplier_price"
                type="text"
                class="form-control"
                v-model="detail.supplier_price.price"
                style="width: 100px"
              />
            </div>
            <div class="col-md-4 form-group">
              <label class="text-secondary-2 font-14 d-block">{{
                $t("label.Out of stock")
              }}</label>
              <div class="custom-control custom-switch d-inline mr-3">
                <input
                  v-if="detail.supplier_price"
                  type="checkbox"
                  class="custom-control-input"
                  id="shwitch"
                  true-value="Y"
                  false-value="N"
                  v-model="detail.supplier_price.is_out_of_stock"
                  v-on:change="
                    setAvailability(index, detail.supplier_price.is_out_of_stock)
                  "
                />
                <label class="custom-control-label" for="shwitch_1"></label>
              </div>
            </div>
            <div class="col-12 text-center">
              <button type="button" class="btn btn-brand-1 mx-2" v-on:click="save()">
                {{ $t("label.Save") }}
              </button>
              <button
                type="button"
                class="btn btn-brand-1 cancel mx-2"
                onclick="goBack()"
              >
                {{ $t("label.Cancel") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EditProduct",
  components: {},
  data() {
    return {
      id: this.$route.params.id,
      detail: {},
      ImageList: [],
    };
  },
  mounted() {
    this.getProductDetail();
  },
  methods: {
    getProductDetail() {
      this.$api
        .getAPI({
          _action: "product/" + this.id,
        })
        .then((res) => {
          // if (res.info?.images.length <= 0) {
          //  res.info.images.push({});
          // }
          this.ImageList = res.info.images;
          this.detail = res.info;
        })
        .catch(() => {});
    },
    save() {
      var obj = {};
      obj.product_id = this.detail.id;
      obj.price = this.detail?.supplier_price?.price;
      this.$api
        .postAPI({
          _action: "product/price/" + this.detail?.supplier_price?.product_id,
          _body: obj,
        })
        .then((res) => {
          this.uploadFileStepByStep();
          this.$notify({
            type: "success",
            text: res.message,
          });
        })
        .catch(() => {});
    },
    setAvailability(index, id) {
      var obj = {};
      for (var i in this.serviceList) {
        if (i == index) {
          obj.has_service_available = this.serviceList[i].service.has_service_available;
        }
      }
      this.$api
        .putAPI({
          _action: "service/availability/" + id,
          _body: obj,
        })
        .then((res) => {
          this.getServiceList(0);
          this.$notify({
            type: "success",
            text: res.message,
          });
        })
        .catch(() => {});
    },

    removeImage(index) {
      this.image_index = index;

      console.log("console log for remove image from the seleted images", index);
      this.ImageList.splice(index, 1);
    },
    deleteImage(data, index) {
      if (!data.id) {
        this.image_index = index;

        console.log("console log for remove image from the seleted images", index);
        this.ImageList.splice(index, 1);
        return;
      }
      this.$api
        .deleteAPI({
          _action: "product/" + this.id + "/image/" + data.id,
        })
        .then((res) => {
          this.getProductDetail();
          this.$noty.success("" + res.message, {
            timeout: 2000,
            layout: "topCenter",
            theme: "semanticui",
          });
        })
        .catch(() => {});
    },
    openImages() {
      document.getElementById("AddPhoto").click();
    },
    onChange(e) {
      var selectedFiles = e.target.files;
      for (let i = 0; i < selectedFiles.length; i++) {
        let img = {
          image: URL.createObjectURL(selectedFiles[i]),
          file: selectedFiles[i],
        };
        this.ImageList.push(img);
      }
    },
    async uploadFileStepByStep() {
      for (let i = 0; i < this.ImageList.length; i++) {
        console.log("console log for images list", this.ImageList[i].file);
        if (this.ImageList[i].file) {
          await this.uploadImage(this.ImageList[i].file);
        }
      }
      this.$router.go(-1);
    },
    async uploadImage(file) {
      this.$api
        .uploadImageAPI({
          _action: "product/" + this.id + "/image",
          _key: "image",
          _file: file,
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
        });
    },
  },
};
</script>
