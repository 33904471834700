<template>
  <main role="main">
    <div class="d-lg-flex no-right-sidebar" id="main-wraper">
      <div class="page-wrapper">
        <div class="page-content">
          <div class="row mb-4 justify-content-center">
            <div class="col-lg-12">
              <div class="row align-items-center">
                <div class="col-md-12 col-sm-12 text-md-left text-center">
                  <h2 class="page-title mb-md-0">{{ $t("label.Company Profile") }}</h2>
                </div>
              </div>
              <ul
                class="nav nav-pills mb-3 tab-style-1 bg-white justify-content-center"
                id="pills-tab"
                role="tablist"
              >
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link active px-4"
                    id="profile-tab"
                    data-toggle="pill"
                    href="#profile"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="true"
                    >{{ $t("label.Company Profile") }}</a
                  >
                </li>
                <li class="nav-item px-4" role="presentation">
                  <a
                    class="nav-link"
                    id="images-tab"
                    data-toggle="pill"
                    href="#images"
                    role="tab"
                    aria-controls="images"
                    aria-selected="false"
                    >{{ $t("label.images") }}</a
                  >
                </li>
              </ul>
              <div class="tab-content" id="pills-tabContent">
                <div
                  class="tab-pane fade active show"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <div
                    class="card card-body content-card mb-4 rounded-l border-0 shadow-sm"
                  >
                    <div class="p-0 border-0">
                      <img
                        src="images/cover-img.jpg"
                        class="card-img-top rounded-l cover-img"
                        alt=""
                      />
                    </div>
                    <form class="form-style">
                      <Form class="form-style floating-form" @submit="update()">
                        <div class="row justify-content-center">
                          <div class="col-lg-4 col-md-5 mb-4 text-center">
                            <div class="mb-2">
                              <img
                                :src="
                                  detail.logo && detail.logo != null
                                    ? detail.logo
                                    : '/images/placeholder-image.jpg'
                                "
                                class="rounded-circle border border-light shadow-sm"
                                style="border-width: 3px !important; object-fit: cover"
                                alt="image thumb"
                                id="preview"
                                width="130"
                                height="130"
                              />
                            </div>
                            <button
                              type="button"
                              class="btn px-3 upload-img-merge-btn btn-secondary w-75 text-capitalize"
                              for="pickFile"
                            >
                              {{ $t("label.Change Logo") }}
                              <input
                                type="file"
                                class="btn upload-img"
                                id="pickFile"
                                @change="selectedFile($event)"
                              />
                            </button>
                          </div>
                        </div>

                        <div class="row justify-content-center">
                          <div class="col-md-10">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="form-group">
                                  <label>{{ $t("label.Company Name") }}</label>
                                  <span class="text-danger">*</span>
                                  <Field
                                    type="text"
                                    id="fname"
                                    name="fname"
                                    v-model="detail.name"
                                    class="form-control filled"
                                    :rules="'required:' + $t(`label.Company Name`)"
                                    :validateOnInput="true"
                                  />
                                </div>
                                <ErrorMessage name="fname" class="validation-msg" />
                              </div>

                              <div class="col-md-6">
                                <div class="form-group">
                                  <label>{{ $t("label.Email") }}</label>
                                  <span class="text-danger">*</span>
                                  <Field
                                    type="email"
                                    id="email"
                                    name="email"
                                    v-model="detail.email"
                                    class="form-control filled"
                                    :rules="'required:' + $t(`label.Email`) + '|email'"
                                    :validateOnInput="true"
                                  />
                                </div>
                                <ErrorMessage name="email" class="validation-msg" />
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label>{{ $t("label.Phone") }}</label>
                                  <span class="text-danger">*</span>
                                  <Field
                                    type="text"
                                    id="phone"
                                    name="phone"
                                    v-model="detail.phone_number"
                                    class="form-control filled"
                                    :rules="'required:' + $t(`label.Phone`) + '|phone'"
                                    :validateOnInput="true"
                                  />
                                </div>
                                <ErrorMessage name="phone" class="validation-msg" />
                              </div>

                              <div class="col-lg-12">
                                <div class="form-group">
                                  <label>{{ $t("label.Company Details") }}</label>
                                  <span class="text-danger">*</span>
                                  <textarea
                                    id="about"
                                    name="about"
                                    class="form-control filled"
                                    style="height: 70px"
                                    v-model="detail.about"
                                    cols="30"
                                    rows="4"
                                    :rules="'required:' + $t(`label.Company Details`)"
                                    :validateOnInput="true"
                                  ></textarea>
                                </div>
                                <ErrorMessage name="about" class="validation-msg" />
                              </div>
                            </div>
                            <div class="text-center mt-3">
                              <button
                                id="submit-button"
                                class="btn btn-brand-1 mb-3 px-5"
                              >
                                {{ $t("label.Save") }}
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    </form>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="images"
                  role="tabpanel"
                  aria-labelledby="images-tab"
                >
                  <!-- <div class="col-md-3 align-self-center">
                                        <button type="button" class="btn px-3 upload-img-merge-btn  btn-secondary font-24 border border-secondary" style="height:35px">
                                            <i class='bx bx-plus' ></i>
                                            <input type="file" class="btn upload-img" id="AddPhoto">
                                        </button> 
                                        </div> -->
                  <div class="text-right mb-3">
                    <button
                      type="button"
                      class="btn btn-brand-1 btn-sm"
                      v-on:click="$refs.textModal.showModal()"
                    >
                      <i class="bx bx-plus"></i> {{ $t("label.Add Image") }}
                    </button>
                  </div>

                  <table class="table table-style-1 table-responsive no-footer">
                    <thead>
                      <tr>
                        <th class="bg-white">{{ $t("label.images") }}</th>
                        <th class="bg-white" width="90">{{ $t("label.Action") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(data, index) in detail.images" :key="index">
                        <td>
                          <img
                            :src="data.image ? data.image : 'placeholder-image.svg'"
                            class="object-cover"
                            height="60"
                            width="120"
                          />
                        </td>
                        <td>
                          <button
                            class="btn btn-action mr-2 mb-md-0 mb-2 text-danger"
                            v-on:click="showDeletePopup(data.id)"
                          >
                            <i class="bx bxs-trash"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- upload image -->
    <TextModal ref="textModal">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="UploadImageLabel">
            {{ $t("label.Upload Image") }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            v-on:click="$refs.textModal.closeModal()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Form @submit="save()">
          <div class="modal-body">
            <div class="custom-select-img">
              <div class="file-upload">
                <div class="image-upload-wrap p-3 mb-4">
                  <input
                    type="file"
                    name="img[]"
                    id="pickFile"
                    class="file-upload-input"
                    v-on:change="selectedFile2($event)"
                    accept="image/*"
                  />
                  <div class="drag-text text-center">
                    <img
                      id="preview2"
                      :src="
                        imageObj.image && imageObj.image != null
                          ? imageObj.image
                          : '/images/Imageplace-holder.svg'
                      "
                      width="80"
                      height="80"
                    />
                    <h3 class="heading font-20" for="pickFile">
                      {{ $t("label.Upload a image") }}
                    </h3>
                    <p class="font-12 text-secondary mb-1">
                      PNG, GIF, JPEG. {{ $t("label.(Max 10 mb)") }}
                    </p>
                    <p class="font-12 text-secondary mb-0">
                      {{ $t("label.(Optional)") }}
                    </p>
                  </div>
                </div>
                <div class="file-upload-content">
                  <img class="file-upload-image mb-3" src="#" alt="your image" />
                  <div class="image-title-wrap">
                    <button type="button" onclick="removeUpload()" class="remove-image">
                      <i class="bx bx-x mr-2" aria-hidden="true"></i>
                      <span class="image-title">{{ $t("label.change Image") }}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <button
              type="button"
              class="btn btn-brand-1 cancel"
              data-dismiss="modal"
              v-on:click="$refs.textModal.closeModal()"
            >
              {{ $t("label.Close") }}
            </button>
            <button id="save-button" class="btn btn-brand-1">
              {{ $t("label.Save") }}
            </button>
          </div>
        </Form>
      </div>
    </TextModal>
  </main>
  <DeleteModal @remove="deleteImage" ref="deleteModal" />
</template>

<script>
import TextModal from "@/components/TextModal";
import DeleteModal from "@/components/DeleteModal";
// import $ from "jquery";
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "CompanyProfile",
  components: {
    TextModal,
    DeleteModal,
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      detail: {},
      file: undefined,
      file2: undefined,
      imageObj: {},
    };
  },
  mounted() {
    this.getCompanyDetail();
  },
  methods: {
    getCompanyDetail() {
      this.$api
        .getAPI({
          _action: "company",
        })
        .then((res) => {
          this.detail = res.info;
        })
        .catch(() => {});
    },
    showDeletePopup(id) {
      this.$refs.deleteModal.showModal(
        this.$t("label.Delete Event"),
        this.$t("label.Are you sure you want to delete"),
        id
      );
    },
    deleteImage(id) {
      this.$api
        .deleteAPI({
          _action: "company/" + this.detail.id + "/image/" + id,
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.$refs.deleteModal.closeModal();
          this.getCompanyDetail();
        })
        .catch(() => {});
    },
    selectedFile(e) {
      let files = e.target.files;
      if (
        files[0].type.toLowerCase() != "image/jpeg" &&
        files[0].type.toLowerCase() != "image/png"
      ) {
        alert(this.$t("label.Invalid file formate, please use jpeg or png file."));
        return;
      }
      const size = (files[0].size / 1024 / 1024).toFixed(2);
      if (size > 10) {
        alert(this.$t("label.File must be less then 10 MB"));
        return;
      }
      var tmppath = URL.createObjectURL(files[0]);
      this.file = files[0];
      document.getElementById("preview").src = tmppath;
    },
    update() {
      this.$api
        .putAPI({
          _action: "company/" + this.detail.id,
          _body: this.detail,
          _buttonId: "submit-button",
        })
        .then((res) => {
          if (res) {
            this.$notify({
              type: "success",
              text: res.message,
            });
            if (this.file) {
              this.uploadImage();
            } else {
              this.$store.commit("setUser", this.detail);
              this.getCompanyDetail();
            }
          }
        })
        .catch(() => {});
    },
    uploadImage() {
      if (!this.file) {
        this.$router.go(-1);
      }
      this.$api
        .uploadImageAPI({
          _action: "company/" + this.detail.id + "/logo",
          _key: "image",
          _file: this.file,
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          // this.detail.image = res.path;
          this.$store.commit("setUser", this.detail);
          this.getCompanyDetail();
        });
    },
    selectedFile2(e) {
      let files = e.target.files;
      if (
        files[0].type.toLowerCase() != "image/jpeg" &&
        files[0].type.toLowerCase() != "image/png"
      ) {
        alert(this.$t("label.Invalid file formate, please use jpeg or png file."));
        return;
      }
      const size = (files[0].size / 1024 / 1024).toFixed(2);
      if (size > 10) {
        alert(this.$t("label.File must be less then 10 MB"));
        return;
      }
      var tmppath = URL.createObjectURL(files[0]);
      this.file2 = files[0];
      this.imageObj.image = tmppath;
      document.getElementById("preview2").src = tmppath;
    },
    save() {
      this.$api
        .uploadImageAPI({
          _action: "company/" + this.detail.id + "/image",
          _key: "image",
          _file: this.file2,
          _buttonId: "save-button",
        })
        .then((res) => {
          this.$refs.textModal.closeModal();
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.getCompanyDetail();
        });
    },
  },
};
</script>

