<template>
  <div class="page-content">
    <div class="row mb-4 justify-content-center">
      <div class="col-lg-12">
        <div class="row align-items-center">
          <div class="col-md-6 text-md-left">
            <h2 class="page-title mb-0">
              <a href="javascript:void(0)" v-on:click="$router.go(-1)" class="back-arrow">
                <i class="bx bx-arrow-back"></i></a
              >{{ $t("label.Request for Withdrawal") }}
            </h2>
          </div>
        </div>
        <div class="card card-body form-style content-card">
          <Form @submit="send()">
            <div class="row mb-md-4 mb-3">
              <div class="col-md-12 mb-2">
                <label>{{ $t("label.Select Banks") }}</label>
                <span class="text-danger">*</span>
                <!-- <div class="selectParent mb-md-0 mb-2 mr-md-3"> -->
                <Field
                  as="select"
                  class="selectParent mb-md-0 mb-2 mr-md-3"
                  v-model="detail.bank_id"
                  name="bank"
                  :rules="'required:' + $t(`label.Bank`) + ',true'"
                >
                  <option value="" disabled="" selected="">
                    {{ $t("label.Select") }}
                  </option>
                  <option v-for="(data, index) in bankList" :key="index" :value="data.id">
                    {{ data.bank_name }}
                  </option>
                </Field>
                <!-- </div> -->
                <ErrorMessage name="bank" class="validation-msg mt-2" />
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label class="">{{ $t("label.Accoount Number") }}</label>
                  <span class="text-danger">*</span>
                  <Field
                    type="text"
                    id="account"
                    name="account"
                    v-model="detail.account_number"
                    class="form-control filled"
                    :rules="'required:' + $t(`label.Accoount Number`)"
                    :validateOnInput="true"
                  />
                </div>
                <ErrorMessage name="account" class="validation-msg mt-2" />
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="">{{ $t("label.Confirm Account Number") }}</label>
                  <span class="text-danger">*</span>
                  <Field
                    type="text"
                    id="confirm_account"
                    name="confirm_account"
                    class="form-control filled"
                    v-on:keydown.space="$event.preventDefault()"
                    :rules="
                      'required:' +
                      $t(`label.Confirm Account Number`) +
                      '|confirmed:@account'
                    "
                    :validateOnInput="true"
                  />
                </div>
                <ErrorMessage name="confirm_account" class="validation-msg" />
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="">{{ $t("label.IBAN Number") }}</label>
                  <span class="text-danger">*</span>
                  <Field
                    type="text"
                    id="iban_number"
                    name="iban_number"
                    class="form-control filled"
                    v-model="detail.iban_number"
                    :rules="'required:' + $t(`label.IBAN Number`)"
                    :validateOnInput="true"
                  />
                </div>
                <ErrorMessage name="iban_number" class="validation-msg" />
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="">{{ $t("label.Mobile Number") }}</label>
                  <span class="text-danger">*</span>
                  <Field
                    type="text"
                    id="phone_number"
                    name="phone_number"
                    class="form-control filled"
                    v-model="detail.mobile_number"
                    :rules="'required:' + $t(`label.Mobile Number`) + '|phone'"
                    :validateOnInput="true"
                  />
                </div>
                <ErrorMessage name="phone_number" class="validation-msg" />
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="">{{ $t("label.Name as given in bank") }}</label>
                  <span class="text-danger">*</span>
                  <Field
                    type="text"
                    id="name"
                    name="name"
                    class="form-control filled"
                    v-model="detail.account_holder_name"
                    :rules="'required:' + $t(`label.Name as given in bank`)"
                    :validateOnInput="true"
                  />
                </div>
                <ErrorMessage name="name" class="validation-msg" />
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="">{{ $t("label.Select Order") }}</label>
                  <Multiselect
                    v-model="detail.orders"
                    :options="orderList"
                    :searchable="true"
                    :createOption="true"
                    mode="tags"
                    @update:model-value="setAmount"
                  />
                  <ErrorMessage name="order" class="validation-msg" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="">{{ $t("label.Amount to Withdrawal") }}</label>
                  <span class="text-danger">*</span>
                  <Field
                    type="text"
                    id="amount"
                    name="amount"
                    class="form-control filled"
                    v-model="detail.total_withdrawal_amount"
                    disabled
                  />
                </div>
                <ErrorMessage name="amount" class="validation-msg" />
              </div>
              <div class="col-12 text-center mt-4 px-0">
                <button id="send-button" class="btn btn-brand-1 mx-2">
                  {{ $t("label.Request") }}
                </button>
                <button
                  type="button"
                  class="btn btn-brand-1 cancel mx-2"
                  v-on:click="$router.go(-1)"
                >
                  {{ $t("label.Cancel") }}
                </button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
  <!--Thank you modal-->
  <TextModal ref="textModal">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" v-on:click="$refs.textModal.closeModal()">
          <img src="images/close.svg" width="15" />
        </button>
      </div>
      <div class="modal-body text-center px-md-4">
        <h5 class="modal-title font-22 mb-4">
          {{ $t("label.Withrawal Request Successful") }}
        </h5>
        <h1 class="text-success display-3">
          <i class="bx bxs-badge-check"></i>
        </h1>
        <button
          type="subbmit"
          class="btn btn-brand-1 cancel"
          v-on:click="this.$router.go(-1)"
        >
          {{ $t("label.OK") }}
        </button>
      </div>
    </div>
  </TextModal>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import TextModal from "@/components/TextModal.vue";
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "RequestForWitdrawal",
  components: { Form, Field, ErrorMessage, TextModal, Multiselect },
  data() {
    return {
      orderList: [],
      bankList: [],
      detail: {},
    };
  },
  mounted() {
    this.getBankList();
    this.getOrderList();
  },
  methods: {
    getBankList() {
      this.$api
        .getAPI({
          _action: "banks",
        })
        .then((res) => {
          this.bankList = res.list;
        });
    },
    getOrderList() {
      this.$api
        .getAPI({
          _action: "withdraw/orders",
        })
        .then((res) => {
          for (var i in res.list) {
            this.orderList.push({
              value: res.list[i].id,
              label: this.$t("label.Order Id: #") + " " + res.list[i].id,
              amount: res.list[i].amount,
            });
          }
        });
    },
    setAmount(a) {
      setTimeout(() => {
        console.log(a);
        this.detail.total_withdrawal_amount = "";
        var amount = 0;
        for (var i = 0; i < this.detail.orders.length; i++) {
          for (var j = 0; j < this.orderList.length; j++) {
            if (this.detail.orders[i] == this.orderList[j].value) {
              amount += this.orderList[j].amount;
            }
          }
        }
        this.detail.total_withdrawal_amount = amount;
      }, 1000);
    },
    send() {
      this.$api
        .postAPI({
          _action: "withdraw-request",
          _body: this.detail,
          _buttonId: "send-button",
        })
        .then((res) => {
          if (res) {
            this.$refs.textModal.showModal();
          }
        });
    },
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style>
.multiselect {
  background: #f9f9f9;
  height: 55px;
  border-radius: 8px;
  border: 0;
}
.multiselect-tag {
  background: #00bb59 !important;
}
.multiselect-tags-search {
  background: #f9f9f9 !important;
}
</style>