import axios from "axios";
import { notify } from "@kyvg/vue3-notification";

var http = null;
var latitude = 0.0;
var longitude = 0.0;


class WebService {
     // constructor({ url = "http://localhost:8000/supplier/" }) {
 constructor({ url = "https://app.garden.sa/supplier/" }) {
    http = axios.create({
      baseURL: url,
    });

    // latitude = localStorage.getItem("latitude");
    // longitude = localStorage.getItem("longitude");
    //   this.getCurrentLocation();
  }

  getAPI({ _action, _body, _buttonId, _hideLoader, _notSendLocation }) {
    let params = new URLSearchParams();
    for (let key in _body) {
      params.set(key, _body[key]);
    }

    if (!_notSendLocation) {
      if (latitude && latitude != null) {
        params.set("latitude", latitude);
        params.set("longitude", longitude);
      }
    }

    if (this.addLoader(_buttonId, _hideLoader)) {
      return;
    }

    return http
      .get(_action, {
        params: params,
        headers: this.getHeader(),
      })
      .then((response) => {
        this.removeLoader(_buttonId, _hideLoader);
        return response.data;
      })
      .catch((e) => {
        this.removeLoader(_buttonId, _hideLoader);
        return this._errorHandler(e);
      });
  }

  postAPI({ _action, _body, _buttonId, _hideLoader }) {
    if (this.addLoader(_buttonId, _hideLoader)) {
      return;
    }

    return http
      .post(_action, JSON.stringify(_body), { headers: this.getHeader() })
      .then((response) => {
        this.removeLoader(_buttonId, _hideLoader);
        return response.data;
      })
      .catch((e) => {
        this.removeLoader(_buttonId, _hideLoader);
        return this._errorHandler(e);
      });
  }

  putAPI({ _action, _body, _buttonId, _hideLoader }) {
    if (this.addLoader(_buttonId, _hideLoader)) {
      return;
    }
    return http
      .put(_action, JSON.stringify(_body), { headers: this.getHeader() })
      .then((response) => {
        this.removeLoader(_buttonId, _hideLoader);
        return response.data;
      })
      .catch((e) => {
        this.removeLoader(_buttonId, _hideLoader);
        return this._errorHandler(e);
      });
  }

  deleteAPI({ _action, _buttonId, _hideLoader }) {
    if (this.addLoader(_buttonId, _hideLoader)) {
      return;
    }
    return http
      .delete(_action, { headers: this.getHeader() })
      .then((response) => {
        this.removeLoader(_buttonId, _hideLoader);
        return response.data;
      })
      .catch((e) => {
        this.removeLoader(_buttonId, _hideLoader);
        return this._errorHandler(e);
      });
  }

  getAccesstoken({ _action, _body, _buttonId, _hideLoader }) {
    if (this.addLoader(_buttonId, _hideLoader)) {
      return;
    }
    var headers = {};
    let params = new URLSearchParams();
    for (let key in _body) {
      params.set(key, _body[key]);
    }

    headers["Content-Type"] = "application/json";
    headers["Authorization"] = "Basic YXV0b3Nwcm86U3BIIW5Y";
    if (localStorage.getItem("language")) {
      headers["x-localization"] = localStorage.getItem("language");
    } else {
      headers["x-localization"] =localStorage.getItem("language");
    }

    return http
      .post(_action + "?" + params, null, { headers: headers })
      .then((response) => {
        console.log("access_token", response.data);
        localStorage.setItem("token", response.data.token);
        this.removeLoader(_buttonId, _hideLoader);
        return response.data;
      })
      .catch((e) => {
        this.removeLoader(_buttonId, _hideLoader);
        return this._loginErrorHandler(e);
      });
  }
  uploadImageAPI({ _action, _file, _key, _body, _buttonId, _hideLoader }) {
    console.log("df", _body)
    if (!_key) {
      _key = "file";
    }
    if (this.addLoader(_buttonId, _hideLoader)) {
      return;
    }
    let formData = new FormData();
    formData.append(_key, _file);
    for (let key in _body) {
      console.log("here", _body[key])
      formData.append(key, _body[key]);
    }

    return http
      .post(_action, formData, { headers: this.getBaseHeader() })
      .then((response) => {
        this.removeLoader(_buttonId, _hideLoader);
        return response.data;
      })
      .catch((e) => {
        this.removeLoader(_buttonId, _hideLoader);
        return this._errorHandler(e);
      });
  }

  _loginErrorHandler(error) {
    if (error.response != null) {
      error = error.response;
    }
    var errorMessage;
    if (!error || !error.status) {
      errorMessage = "Server Not Responding";
    } else if (error.status === 400 || error.status === 404) {
      errorMessage =
        (error && error.data && error.data.message) ||
        "Invalid Username or Password";
    } else if (error.status === 500) {
      errorMessage = "Server Error";
    } else {
      errorMessage =
        (error & error.data && error.data.message) || "Bad Response";
    }
    // alert(error.data.message)
    notify({
      type: "error",
      text: errorMessage,
    });
    throw new Error(errorMessage);
  }

  _errorHandler(error) {
    if (error.response != null) {
      error = error.response;
    }
    var errorMessage;
    if (!error || !error.status) {
      errorMessage = "Server Not Responding";
    } else if (error.status === 401) {
      errorMessage =
        (error && error.data && error.data.message) || "Bad Response";
      this.logout();
    } else if (error.status === 500) {
      errorMessage = "Server Error";
    } else {
      errorMessage =
        (error && error.data && error.data.message) || "Bad Response";
    }
    // alert(error.data.message)
    notify({
      type: "error",
      text: errorMessage,
    });
    throw new Error(errorMessage);
  }

  logout() {
    localStorage.clear();
    window.location.href = "/login";
  }

  getHeader() {
    var headers = this.getBaseHeader();
    headers["Content-Type"] = "application/json";
    if (localStorage.getItem("language")) {
      headers["x-localization"] = localStorage.getItem("language");
    } else {
      headers["x-localization"] = localStorage.getItem("language");
    }
    return headers;
  }

  getBaseHeader() {
    var headers = {};
    if (localStorage.getItem("access_token")) {
      headers["Authorization"] =
        "Bearer " + localStorage.getItem("access_token");
    }

    return headers;
  }

  addLoader(id) {
    if (id) {
      var button = document.getElementById(id);
      if (button.disabled) {
        return true;
      }
      button.disabled = true;
      var loader = document.createElement("i");
      loader.className = "fa fa-spinner fa-spin mr-2";
      button.prepend(loader);
      return false;
    }
  }

  removeLoader(id) {
    if (id) {
      var button = document.getElementById(id);
      button.disabled = false;
      button.removeChild(button.childNodes[0]);
    }
  }

  getLatitude() {
    return latitude;
  }

  getLongitude() {
    return longitude;
  }

  getCurrentLocation() {
    const success = (position) => {
      latitude = position.coords.latitude;
      longitude = position.coords.longitude;
      localStorage.setItem("latitude", latitude);
      localStorage.setItem("longitude", longitude);
    };

    const error = (err) => {
      console.log(err);
    };
    navigator.geolocation.getCurrentPosition(success, error);
  }
}

export default new WebService({
  // url: "https://gardeningapi.winayak.com/supplier/",
  //  url: 'http://localhost:8000/supplier/'
 url: "https://app.garden.sa/supplier/"
});
